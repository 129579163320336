import React, { Component } from 'react'
import { Text,
         View,
         ScrollView,
         TouchableOpacity,
         FlatList,
         StyleSheet,
         Image,
         Platform,
         Modal,
         Dimensions
        } from 'react-native';
import ModalWeb from 'modal-react-native-web';
import { connect } from 'react-redux';
import {getAllRecords, getAllTaskFromLog} from '../actions/records';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { navigateRecord } from "../actions/menu";
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import moment from "moment-timezone";
import { setTaskSelected } from "../actions/tasks";
import { getGroupDetail, setIsLogGroupTask, setIsLogGroup } from "../actions/loggroups";
import colors from "../constants/colors";

const recordTypes = (isLog = false) =>  ([
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Edit Required',
    value: 'edit_required',
  },
  {
    label: 'Complete',
    value: 'complete',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  ...(!isLog ?
    [{
      label: 'Archived',
      value: 'archived',
    }]
    : []
    ), 
    ...(isLog ? 
    [{
      label: 'Archive',
      value: 'archive',
    }]
    : []
    ),
    ...(isLog ? 
    [{
      label: 'Pending',
      value: 'pending',
    }]
    : []
    ), 
  ]);
  

class Records extends Component {
    constructor(props) {
      super(props);
      this.state = {
        filter: 'all',
        filterOpen: false,
        filterLog:'all',
        filterLogOpen: false,
        showRecords: false,
        showLogs: false,
        btnName: "Show Records",
        btnNameLog: "Show Logs Records",
      };      
    }

    componentDidMount(){
        //this.props.getAllRecords();
        this.props.getAllRecords({page:1});
        this.props.getAllTaskFromLog({page:1});   
        
        // console.log("++++++++++++++++++++++++++++++++++++++")
        // console.log(this.props)
        // console.log("++++++++++++++++++++++++++++++++++++++")
    }
    translateStatus(name){
        switch(name){
            case "all" : return "All";
            case "in_progress" : return "In Progress";
            case "edit_required" : return "Edit Required";
            case "complete" : return "Complete";
            case "approved" : return "Approved";
            case "archived" : return "Archived";
            case "logroups" : return "Form Groups";
            case "archive" : return "Archive";
            case "pending" : return "Pending";
            default : "In Progress";            
        }
    }

    translateIcon(name){
        switch(name){
            case "all" : return "filter-variant";
            case "in_progress" : return "progress-clock";
            case "edit_required" : return "lead-pencil";
            case "complete" : return "checkbox-marked-circle";            
            case "approved" : return "checkbox-marked-circle"; 
            case "archived" :
            case "archive": 
              return "checkbox-marked-circle";    
            case "logroups" : return "apps";         
            default : return "progress-clock";
        }
    }

    getIconStyle(name){
      switch(name){
          case "in_progress" : return styles.iconInProgress;
          case "edit_required" : return styles.iconEditRequired;
          case "complete" : return styles.iconReadyForReview;
          case "approved" : return styles.iconReadyForApproval;
          case "archived" : 
          case "archive": 
            return styles.iconArchived;           
          default : return styles.iconDefault;
      }
  }

  getTimeConverted(date) {
    let d = moment.tz(date, "UTC");  
    if( moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
      return "Today at " + moment.tz(d, moment.tz.guess(true)).format("H:mm");
    else
      return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
  }

  filterItem(item){
    return (
      <TouchableOpacity style={item.value== this.state.filter ? styles.filterItemSelected : styles.filterItem} onPress={()=> this.setState({filter: item.value})}>
        <View style={styles.iconContainer}>
          <Icon 
                  name={this.translateIcon(item.value)} 
                  style={this.getIconStyle(item.value)}
                />
        </View>
        <View style={styles.recordContainer}>
          <Text style={{padding: 10, fontSize: 15}}>{item.label}</Text>
        </View>
        
      </TouchableOpacity>
      
    );
  }

  filterLogItem(item){
    return (
      <TouchableOpacity style={item.value== this.state.filterLog ? styles.filterItemSelected : styles.filterItem} onPress={()=> this.setState({filterLog: item.value})}>
        <View style={styles.iconContainer}>
          <Icon 
                  name={this.translateIcon(item.value)} 
                  style={this.getIconStyle(item.value)}
                />
        </View>
        <View style={styles.recordContainer}>
          <Text style={{padding: 10, fontSize: 15}}>{item.label}</Text>
        </View>    
      </TouchableOpacity>
      
    );
  }

  Item(item) {          
      const createdAt = this.getTimeConverted(item.created_at);
      return (
          <TouchableOpacity 
              style={styles.item}
              onPress={() => {                    
                  this.props.setSubmissionSelected({ ...item });
                  this.props.setIsSubmission(true);                   
                  this.props.navigateRecord(
                    item.id,
                    item.name,
                    false
                  );
                  this.props.setTaskSelected({});
                }}
              >
            <View style={styles.iconContainer}>
              <Icon 
                name={this.translateIcon(item.status)} 
                style={this.getIconStyle(item.status)}
              />
            </View>
            <View style={styles.recordContainer}>
                <View style={styles.itemsBody}>      
                  <View style={styles.smartBox}>    
                      <Text style={styles.recordName}>{item.name}</Text>                                         
                  </View>                    
                </View>
                <View style={styles.itemsFooter}>
                  { typeof item.owner != "undefined" &&
                    <View style={styles.smartBox}>                   
                      <Text style={styles.transactionPeer}>{item.owner.first_name + " " + item.owner.last_name}</Text>
                    </View>
                  }    
                  <View style={styles.smartBox}>  
                    <Text style={styles.recordDate}>{this.getTimeConverted(item.updated_at)}</Text>
                  </View>  
                </View>
                <Text>
                  Created at: {createdAt}
                </Text>
              </View>
          </TouchableOpacity>          
      );
    }


    TaskItem(item) {
      const createdAt = this.getTimeConverted(item.created_at);
      return (
          <TouchableOpacity 
              style={styles.item}
              onPress={() => {     
                  this.props.setTaskSelected({...item});
                  this.props.getGroupDetail(item.log_group_id);   
                  this.props.setIsLogGroup(true);
                  this.props.navigation.navigate('GroupView');            
                }}
              >
            <View style={styles.iconContainer}>
              <Icon 
                name={this.translateIcon(item.status)} 
                style={this.getIconStyle(item.status)}
              />
            </View>
            <View style={styles.recordContainer}>
                <View style={styles.itemsBody}>      
                  <View style={styles.smartBox}>    
                      <Text style={styles.recordName}>{item.name}</Text>                                         
                  </View>                    
                </View>
                <View style={styles.itemsFooter}>
                  <View style={styles.smartBox}>  
                    <Text style={styles.recordDate}>{this.getTimeConverted(item.updated_at)}</Text>
                  </View>  
                </View>
                <Text>
                  Created at: {createdAt}
                </Text>
              </View>
          </TouchableOpacity>          
      );
      
    }


    filterRecords(records){
      const {filter} = this.state;
      if(filter == "all" || filter == null){         
        return records.filter(x=> x.logroup==''||typeof x.logroup==='undefined');
      }
      else if(filter == "logroups"){        
        return records.filter(x=> x.logroup!='');
      }
      else if(filter == "in_progress")
        return records.filter(x=> x.status == filter && (x.logroup==''||typeof x.logroup==='undefined') ).concat(records.filter((x=> (x.status == "" || x.status == null || x.status == "ready_for_review" || x.status == "ready_for_approval" || x.status == "rejected") &&(x.logroup==''||typeof x.logroup==='undefined'))))
      else
        return records.filter(x=> (x.status == filter)&&(x.logroup==''||typeof x.logroup==='undefined'));
    }

    filterLogRecords(records){
      const {filterLog} = this.state;
      switch(filterLog){
        case "all":
          return records;
        default:
          return records.filter( record => record.status == filterLog);
      }
    }

    paginator(){
      const {currentPage,  pageSize, getAllRecords, records} = this.props;

      let tasksCount = records.totalCount;

      const nPages = tasksCount/pageSize % 10 == 0 ? Math.trunc(tasksCount/pageSize) : Math.trunc(tasksCount/pageSize) +1;
      return <View style={{flex:1, flexDirection:'row', backgroundColor:'#002f6c', alignContent:'center', alignItems:'center', justifyContent:'center'}}>
        {currentPage != 1 && 
          <TouchableOpacity onPress={()=> getAllRecords({page:parseInt(currentPage)-1}) } >
            <Icon name="menu-left-outline" style={{color:'#fff', fontSize:38}} />
          </TouchableOpacity>}
        <Text style={{color:'#fff', fontSize: 18}}>{currentPage} of {nPages} </Text>
        {currentPage != nPages && 
          <TouchableOpacity onPress={()=> getAllRecords({page: parseInt(currentPage) + 1}) } >
            <Icon name="menu-right-outline" style={{color:'#fff', fontSize:38}} />
          </TouchableOpacity>}
      </View>
    }

    logPaginator(){
      const {currentLogPage, taskLogCount, logPageSize, getAllTaskFromLog} = this.props;
      const nPages = taskLogCount/logPageSize % 10 == 0 ? Math.trunc(taskLogCount/logPageSize) : Math.trunc(taskLogCount/logPageSize) +1;
      return <View style={{flex:1, flexDirection:'row', backgroundColor:'#002f6c', alignContent:'center', alignItems:'center', justifyContent:'center'}}>
        {currentLogPage != 1 && 
          <TouchableOpacity onPress={()=> getAllTaskFromLog({page:parseInt(currentLogPage)-1}) } >
            <Icon name="menu-left-outline" style={{color:'#fff', fontSize:38}} />
          </TouchableOpacity>}
        <Text style={{color:'#fff', fontSize: 18}}>{currentLogPage} of {nPages} </Text>
        {currentLogPage != nPages && 
          <TouchableOpacity onPress={()=> getAllTaskFromLog({page: parseInt(currentLogPage) + 1}) } >
            <Icon name="menu-right-outline" style={{color:'#fff', fontSize:38}} />
          </TouchableOpacity>}
      </View>
    }

    render() {
        const {records, logRecords} = this.props; 
        
      console.log("++++++++++++++++++++++++++++++++++++++")
      console.log(this.props)
      console.log("++++++++++++++++++++++++++++++++++++++")
        let recordsVar=records.records;  
        const placeholder = {
          label: 'Select a record status...',
          value: null,
          color: '#9EA0A4',
        };

        if (Platform.OS === 'web') {
          return (

            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 2 }} />
              <View style={{ flex: 2, maxWidth: 800, padding: 20, margin: 30, borderRadius: 20, backgroundColor: "#fff" }}  >
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showRecords: !this.state.showRecords });
                    if (this.state.showRecords) {
                      this.setState({ btnName: "Show records" });
                    } else {
                      this.setState({ btnName: "Hide records" });
                    }
                  }}
                  style={styles.appButtonContainer}
                >
                  <Text style={styles.appButtonText}>{this.state.btnName}</Text>
                </TouchableOpacity>
                {this.state.showRecords ? (
                  <View>
                    <TouchableOpacity onPress={() => this.setState({ filterOpen: true })} style={{ alignItems: 'center' }}>
                      <View style={{ flexDirection: "row", padding: 5 }}>
                        <Icon name={this.translateIcon(this.state.filter)} style={this.getIconStyle(this.state.filter)} />
                        <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}>{this.translateStatus(this.state.filter)}</Text>
                      </View>
                    </TouchableOpacity>
                    <View style={styles.centeredView}>
                      <ModalWeb
                        animationType="none"
                        transparent={true}
                        visible={this.state.filterOpen}>

                        <View style={styles.centeredView}>
                          <View style={styles.modalView}>
                            <Icon onPress={() => this.setState({ filterOpen: false })} name="close" style={styles.filterClose} />
                            <Text style={{ marginBottom: 10, fontWeight: '500' }}>Please select a filter:</Text>
                            <FlatList
                              data={recordTypes()}
                              renderItem={({ item }) => this.filterItem(item)}
                              keyExtractor={item => item.value}
                            />
                          </View>
                        </View>
                      </ModalWeb>

                    </View>
                    {recordsVar && recordsVar.length > 0 ?
                      <FlatList
                        data={this.filterRecords(recordsVar)}
                        renderItem={({ item }) => this.Item(item)}
                        keyExtractor={item => item._id.toString()}
                      /> : null
                    }
                    {this.props.records.records.length > 0 ? this.paginator() : <Text style={{ textAlign: 'center' }}>No records found</Text>}

                  </View>
                ) : null}
                <Separator />
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showLogs: !this.state.showLogs });
                    if (this.state.showLogs) {
                      this.setState({ btnNameLog: "Show Log records" });
                    } else {
                      this.setState({ btnNameLog: "Hide Log records" });
                    }
                  }}
                  style={styles.appButtonContainer}
                >
                  <Text style={styles.appButtonText}>{this.state.btnNameLog}</Text>
                </TouchableOpacity>
                {this.state.showLogs ? (
                  <View>
                    <TouchableOpacity onPress={() => this.setState({ filterLogOpen: true })} style={{ alignItems: 'center' }}>
                      <View style={{ flexDirection: "row", padding: 5 }}>
                        <Icon name={this.translateIcon(this.state.filterLog)} style={this.getIconStyle(this.state.filterLog)} />
                        <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}>{this.translateStatus(this.state.filterLog)}</Text>
                        <Text style={{ alignSelf: 'center', fontSize: 15, fontWeight: '600' }}> Log Records</Text>
                      </View>
                    </TouchableOpacity>
                    <View style={styles.centeredView}>
                      <ModalWeb
                        animationType="none"
                        transparent={true}
                        visible={this.state.filterLogOpen}>
                        <View style={styles.centeredView}>
                          <View style={styles.modalView}>
                            <Icon onPress={() => this.setState({ filterLogOpen: false })} name="close" style={styles.filterClose} />
                            <Text style={{ marginBottom: 10, fontWeight: '500' }}>Please select a filter:</Text>
                            <FlatList
                              data={recordTypes(true)}
                              renderItem={({ item }) => this.filterLogItem(item)}
                              keyExtractor={item => item.value}
                            />
                          </View>
                        </View>
                      </ModalWeb>

                    </View>
                    <FlatList
                      data={this.filterLogRecords(logRecords)}
                      renderItem={({ item }) => this.TaskItem(item)}
                      keyExtractor={item => item._id.toString()}
                    />
                    {this.props.logRecords.length > 0 ? this.logPaginator() : <Text style={{ textAlign: 'center' }}>No log records found</Text>}

                  </View>
                ) : null}
              </View>
              <View style={{ flex: 2 }} />
            </View>

          )
        }else{
        
        return (

          <ScrollView style={{flex:1, flexDirection:"column", backgroundColor:"#F0F0F0"}}>
               <TouchableOpacity

                  onPress={() => {
                    this.setState({ showRecords: !this.state.showRecords });
                    if (this.state.showRecords) {
                      this.setState({ btnName: "Show records" });
                    } else {
                      this.setState({ btnName: "Hide records" });
                    }
                  }}
                  style={styles.appButtonContainer}
                  >
                <Text style={styles.appButtonText}>{this.state.btnName}</Text> 
                </TouchableOpacity>
              {this.state.showRecords ? (
                <View>
               <TouchableOpacity onPress={()=> this.setState({filterOpen: true})} style={{alignItems: 'center'}}>
                 <View style={{flexDirection: "row", padding: 5}}>
                  <Icon name={this.translateIcon(this.state.filter)} style={this.getIconStyle(this.state.filter)}/>
                  <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '600'}}>{this.translateStatus(this.state.filter)}</Text>
                 </View>                
               </TouchableOpacity>
               <View style={styles.centeredView}>
               {Platform.OS=='web'?
               <ModalWeb
               animationType="none"
               transparent={true}
               visible={this.state.filterOpen}> 
                              
             <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Icon onPress={()=> this.setState({filterOpen: false})} name="close" style={styles.filterClose} />
                <Text style={{marginBottom: 10, fontWeight:'500'}}>Please select a filter:</Text>
                <FlatList
                    data={recordTypes()}
                    renderItem={({ item }) => this.filterItem(item) }
                    keyExtractor={item => item.value}
                />
              </View>
             </View>
           </ModalWeb>:
           <Modal
           animationType="none"
           transparent={true}
           visible={this.state.filterOpen}> 
                          
         <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Icon onPress={()=> this.setState({filterOpen: false})} name="close" style={styles.filterClose} />
            <Text style={{marginBottom: 10, fontWeight:'500'}}>Please select a filter:</Text>
            <FlatList
                data={recordTypes()}
                renderItem={({ item }) => this.filterItem(item) }
                keyExtractor={item => item.value}
            />
          </View>
         </View>
       </Modal>
              
              
              }  
                   
               </View>  
               {recordsVar && recordsVar.length>0?                  
               <FlatList
                    data={this.filterRecords(recordsVar)}
                    renderItem={({ item }) => this.Item(item) }
                    keyExtractor={item => item._id.toString()}
                />:null
               }
                  { this.props.records.records.length > 0 ? this.paginator() : <Text style={{textAlign: 'center'}}>No records found</Text>}
         
                </View>
                ) : null} 
                <Separator/>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showLogs: !this.state.showLogs });
                    if (this.state.showLogs) {
                      this.setState({ btnNameLog: "Show Log records" });
                    } else {
                      this.setState({ btnNameLog: "Hide Log records" });
                    }
                  }}
                  style={styles.appButtonContainer}
                >
                  <Text style={styles.appButtonText}>{this.state.btnNameLog}</Text>
                </TouchableOpacity>
                {this.state.showLogs ? (
              <View>
            <TouchableOpacity onPress={()=> this.setState({filterLogOpen: true})} style={{alignItems: 'center'}}>
                  <View style={{flexDirection: "row", padding: 5}}>
                    <Icon name={this.translateIcon(this.state.filterLog)} style={this.getIconStyle(this.state.filterLog)}/>
                    <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '600'}}>{this.translateStatus(this.state.filterLog)}</Text>
                    <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '600'}}> Log Records</Text>
                  </View>                
                </TouchableOpacity>
                <View style={styles.centeredView}>
                {Platform.OS=='web'?
                <ModalWeb
                animationType="none"
                transparent={true}
                visible={this.state.filterLogOpen}>                
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <Icon onPress={()=> this.setState({filterLogOpen: false})} name="close" style={styles.filterClose} />
                  <Text style={{marginBottom: 10, fontWeight:'500'}}>Please select a filter:</Text>
                  <FlatList
                      data={recordTypes(true)}
                      renderItem={({ item }) => this.filterLogItem(item) }
                      keyExtractor={item => item.value}
                  />
                </View>
              </View>
              </ModalWeb>
                
                :<Modal
                    animationType="none"
                    transparent={true}
                    visible={this.state.filterLogOpen}>                
                  <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                      <Icon onPress={()=> this.setState({filterLogOpen: false})} name="close" style={styles.filterClose} />
                      <Text style={{marginBottom: 10, fontWeight:'500'}}>Please select a filter:</Text>
                      <FlatList
                          data={recordTypes(true)}
                          renderItem={({ item }) => this.filterLogItem(item) }
                          keyExtractor={item => item.value}
                      />
                    </View>
                  </View>
                </Modal>}    
                </View>                    
                <FlatList
                      data={this.filterLogRecords(logRecords)}
                      renderItem={({ item }) => this.TaskItem(item) }
                      keyExtractor={item => item._id.toString()}
                  />
                  { this.props.records.length > 0 ? this.logPaginator() : <Text style={{textAlign: 'center'}}>No log records found</Text>}
         
                  </View>
                  ) : null}
              </ScrollView>

        )
    }
  }
}

const mapStateToProps = (state) => {
    return {
      records: state.records.get('records'),
      logRecords: state.records.get('logRecords'), 
      currentPage: state.tasks.get('currentPage'),
      tasksCount: state.tasks.get('tasksCount'),
      pageSize: state.tasks.get('pageSize'),
      currentLogPage: state.tasks.get('currentLogPage'),
      taskLogCount: state.tasks.get('taskLogCount'),
      logPageSize : state.tasks.get('logPageSize'),   
    };
};

const Separator = () => (
  <View style={styles.separator} />
);

export default connect(mapStateToProps, (dispatch, props) => {
    return {
      /*getAllRecords: () => {
        dispatch(getAllRecords());
      },*/
      getAllRecords: (params) => {
        dispatch(getAllRecords(params));
      },  
      getAllTaskFromLog: (params) => {
        dispatch(getAllTaskFromLog(params));
      },
      setSubmissionSelected: (value) =>{
        dispatch(setSubmissionSelected(value));
      },
      setIsSubmission: (value) =>{
        dispatch(setIsSubmission(value));
      },
      navigateRecord: (id, name, newReport) =>{
        props.navigation.navigate('FormView', { formId: id, title: name, newReport:newReport, submission: null, task_id: null, log_group_id:null});
        //dispatch(navigateRecord(id, name, newReport));
      },
      setTaskSelected: (value) => {
        dispatch(setTaskSelected(value));
      },
      getGroupDetail: (id) => {
        dispatch(getGroupDetail(id));
      },
      setIsLogGroup: (val) => {
        dispatch(setIsLogGroup(val))
      },
      setIsLogGroupTask: (val) => {
        dispatch(setIsLogGroupTask(val));   
      props.navigation.navigate('GroupView');
      }
    };
})(Records);

const styles = StyleSheet.create({    
    filterItem: {
        flexGrow: 1,
        flexDirection: "row",
        backgroundColor: 'lightgray',
        backgroundColor: '#fff',   
        borderRadius: 5,  
        marginBottom: 2,        
        marginHorizontal: 2,
        borderColor: "lightgray",
        borderWidth: 1,
        marginBottom: 2,       
    },  
    filterItemSelected: {
      flexGrow: 1,
      flexDirection: "row",
      backgroundColor: "#F5F5F5",         
      borderRadius: 5,  
      marginBottom: 2,        
      marginHorizontal: 2,
      borderColor: "lightgray",
      borderWidth: 2,
      marginBottom: 2,       
  },
    item: {
        flexDirection: "row",
        backgroundColor: 'lightgray',
        backgroundColor: '#fff',   
        borderRadius: 5,  
        marginBottom: 2,        
        marginHorizontal: 2,
    },
    itemsBody: {
        flexDirection: "row",
        backgroundColor: 'lightgray',                    
        backgroundColor: '#fff',        
        flexGrow: 1,        
    },
    itemsFooter: {
        flexDirection: "row",
        backgroundColor: 'lightgray',              
        backgroundColor: '#fff',     
    },
    smartBox: {
        flexGrow: 1,
        justifyContent: "center",
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",                 
    },
    modalView: {
      height: 370,      
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    iconContainer: {         
        justifyContent: "center",
    },  
    recordContainer: {
        flexGrow: 1, 
        
        justifyContent: "center",
    },       
    iconInProgress: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
      
    },
    iconDefault:{
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: "#7B7F7F",
    },
    filterClose: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
      position: 'absolute',
      top: 5,
      right: 0,
    
    },
    iconEditRequired: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
    },
    iconReadyForReview: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
    },
    iconReadyForApproval: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightblue',
    },
    iconApproved: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgreen',
    },   
    iconArchived: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgreen',
    },
    recordName: {      
        fontSize: 15,
        marginTop: 10,
        marginRight:2,
        fontWeight: "bold",
        textAlign: "left",
        alignSelf: "flex-start",
    },   
    transactionPeer: {      
        fontSize: 18,
        marginTop: 1, 
        color: "gray",    
        alignSelf: "flex-start",
    },
    recordDate: {
        fontSize: 18,
        marginTop: 1,     
        color: "gray",
        alignSelf: "flex-end",
    },
    topBar: {
      paddingVertical: 20,
      backgroundColor: colors.primary,
      justifyContent: "center",
      alignItems: "center",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 5,
      shadowOpacity: 0.2,
      shadowColor: "black",
      zIndex: 10,
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#cfd8dc",
      paddingVertical: 16,
      paddingHorizontal: 12,
    },
    appButtonText: {
      fontSize: 14,
      color: "#002f6c",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase",
    },
    separator: {
      marginVertical: 20,
      borderBottomColor: '#002f6c',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
});