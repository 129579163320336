import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class LotCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      dialogVisible: false,
    };
  }

  // set initial value 
  componentWillMount(nextProps, nextState) {
    this.setState({ value: this.props.initial });
  }

  render() {
    const { value } = this.state;
    const { id, onChange, label,customlot, isIssuable, onSubmitIssue, isCompleted} = this.props;
    console.log("CUSTOMLOT "+JSON.stringify(this.props))
    return (
      <>  
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
      />

      <View style={styles.container}>
        {!customlot?
        <Text style={styles.label}>{label}</Text>:null
        }
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            this.setState({dialogVisible:true});
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        <TextInput
          value={value}
          style={styles.input}
          placeholder={this.props.placeholder}
          onChangeText={(val) => {
            this.setState({ value: val });
            onChange(id, val);
          }}
          
        />
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 10,
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    alignSelf: "stretch",
    height: 50,
    flex: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
