import React, { Component } from "react";
import { View, StyleSheet, Text, TouchableOpacity, Platform } from "react-native";

import Section from "./components/section";
import DateTime from "./components/datetime";
import InputWithLabel from "./components/inputwithlabel";
import DropDown from "./components/dropdown";
import Input from "./components/input";
import Picture from "./components/picture";
import TextArea from "./components/textarea";
import Signature from "./components/signature";
import YesNo from "./components/yesno";
import YesNoAll from "./components/yesnoall";
import Counter from "./components/counter";
import Lotcode from "./components/lotcode";
import Document from "./components/document";

export default class FormComponent extends Component {
  renderComponent = (
    id,
    type,
    label,
    first,
    initial,
    onChange,
    options,
    checkboxkey,
    status,
    lotCodeType,
    isIssuable,
    onSubmitIssue,
    placeholder

  ) => {
    switch (type) {
      case "section":
        return <Section showSeparator={!first} label={label} />;
      case "datetime":
        return <DateTime 
                  id={id} initial={initial} 
                  label={label} 
                  placeholder={placeholder}
                  onChange={onChange} 
                  isIssuable={isIssuable}
                  onSubmitIssue={onSubmitIssue} 
                  isCompleted={status == "complete"}           
                />;
      case "inputlabel":
        return (
          <InputWithLabel
            id={id}
            initial={initial}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "input":
        return (
          <Input 
            id={id} 
            initial={initial} 
            label={label} 
            placeholder={placeholder}
            onChange={onChange} 
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue} 
            isCompleted={status == "complete"}           
            />
        );
      case "dropdown":
        return (
          <DropDown
            id={id}
            label={label}
            initial={initial}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "picture":
        return (
          <Picture
            id={id}
            label={label}
            initial={initial}
            onChange={onChange}
            placeholder={placeholder}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "textarea":
        return (
          <TextArea
            id={id}
            label={label}
            initial={initial}
            onChange={onChange}
            placeholder={placeholder}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "signature":
        return (
          <Signature
            id={id}
            label={label}
            initial={initial}
            placeholder={placeholder}
            onChange={onChange}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "yesno":
        return (
          <YesNo
            id={id}
            label={label}
            key={checkboxkey}
            onChange={onChange}
            placeholder={placeholder}
            initial={initial}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "yesnoall":
        return (
          <YesNoAll
            id={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            initial={initial}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
      case "timer":
        return (
          <Counter
            id={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            initial={initial}
            autoStart={false}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
        case "timerauto":
          return (
            <Counter
              id={id}
              label={label}
              placeholder={placeholder}
              onChange={onChange}
              initial={initial}
              autoStart={true}
              status={status}
              isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
            />
          );
      case "lotcode":
      case "customlotcode":
        return (
          <Lotcode
            id={id}
            placeholder={placeholder}
            initial={initial}
            label={label}
            customlot={lotCodeType}
            onChange={onChange}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
        );
        case "inventory":
        return(<>
          <Lotcode
            id={id}
            initial={initial.lotcode}
            placeholder={placeholder}
            label={label}
            onChange={(id,val) => onChange(id,val, "lotcode")}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
          />
          <Input id={id}  placeholder={placeholder} initial={initial.productname} label="Product Name" onChange={(id,val) => onChange(id,val,"productname")} isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue} isCompleted={status == "complete"}/>
          <Input id={id}  placeholder={placeholder} initial={initial.quantity} label="Quantity" onChange={(id,val) => onChange(id,val,"quantity")} isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue} isCompleted={status == "complete"}
          />
        </>);
        case "document":
          return (
              <Document
                id={id}
                label={label}
                placeholder={placeholder}
                initial={initial}
                onChange={onChange}
                isIssuable={isIssuable}
                onSubmitIssue={onSubmitIssue}
                isCompleted={status == "complete"}
              />
            );  
      default:
        return (
          <TouchableOpacity onPress={() => onChange(id, "1")}>
            <Text>{id}</Text>
            <Text>{type}</Text>
            <Text>{label}</Text>
          </TouchableOpacity>
        );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.type === "yesno") {
    }
  }

  render() {
    const {
      id,
      type,
      label,
      first,
      onChange,
      initial,
      options,
      checkboxkey,
      status,
      customlot,
      isIssuable,
      onSubmitIssue,
      placeholder
    } = this.props;
    console.log(this.props)
    return (
      <View style={styles.container}>
        {this.renderComponent(
          id,
          type,
          label,
          first,
          initial,
          onChange,
          options,
          checkboxkey,
          status,
          customlot,
          isIssuable,
      onSubmitIssue,
      placeholder
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
