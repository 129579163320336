import React, { Component } from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import CheckBox from '../widgets/checkbox';
import NewIssue from "../dialogs/newIssue";

export default class YesNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      dialogVisible: false,
    };
  }

  // set initial value
  componentWillMount(nextProps, nextState) {
    this.setState({value: this.props.initial});
  }

  render() {
    const { value } = this.state;
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder } = this.props;
    return <> 
    <NewIssue 
      visible={this.state.dialogVisible} 
      id={id} 
      label={label} 
      onSubmitIssue={onSubmitIssue} 
      handleClose={()=>this.setState({dialogVisible:false})} 
      />
    <View style={styles.container}>
    <Text style={styles.label}>{label+" "}<Text style={{fontStyle: 'italic',color: '#002f6c',fontSize: 18,}}>{placeholder}</Text></Text>
       
      {
        (isIssuable && isCompleted) && 
        <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
          this.setState({dialogVisible:true});
        }}>
          <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
        </TouchableOpacity>
      }

      <CheckBox initial={this.props.initial} onPress={(val) => { console.log('CHECKBOX value: ' + val); this.setState({value: val}); onChange(id, val); }} />
    </View>
    </>;
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
