import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  Image,
  Platform,
  Alert,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
//import SignaturePad from '../../widgets/signaturepad';
import * as FileSystem from "expo-file-system";
import base64 from "base64-js";
import SignaturePad from "react-native-signature-pad";
import colors from "../../constants/colors";
import ExpoPixi from "expo-pixi";
import SignatureCanvas from 'react-signature-canvas';
import NewIssue from "../dialogs/newIssue";

export default class Signature extends Component {
  state = {
    disabled: false,
    isSigning: 0,
    signature: this.props.initial?this.props.initial:"",
    show: true,
    dialogVisible: false,
  };
  signatureRef = React.createRef();
  sendSignature(sig) {
    const { id, onChange } = this.props;
    onChange(id, sig);
  }

  decodeCredential(input) {
    if (
      input && // if the input exists
      typeof input === "string" && // and it's a string
      input.startsWith("__enc__") === true // and it's encoded yet
    ) {
      const newInput = input.replace("__enc__", ""); // remove the prepending string
      const utf8Bytes = base64.decode(newInput); // base64 decode it
      const output = utf8.decode(utf8Bytes); // utf8 decode it
      return output.replace(SALT, "");
    }    
    
    return input;
  }

  signatureChanged = (paths) => {
    this.setState({ signature: paths, disabled: false });
  };

  _signaturePadError = (error) => {
    //console.error(error);
  };

  _signaturePadChange = ({ base64DataUrl }) => {
    this.setState({
      signature2: base64DataUrl,
      signature: base64DataUrl,
      disabled: false,
    });
    this.sendSignature(base64DataUrl);
  };

  clearSignature() {
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  onChange = () => {
    for (let line of this.sketch.lines) {
      console.log(line.points);
    }
  };
  clearSignatureAndroid(){
    
    if (this.sketch.stage&&this.sketch.stage.children.length > 0) {
      this.sketch.stage.removeChildren();
      this.sketch.renderer._update();
    }
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  cancelSignature(){
    
    this.signatureRef.current.clear();
    
  }

  getURL(){
    if(this.state.signature != null){
      return this.state.signature.includes('data:image/')&&this.state.signature?this.state.signature:`data:image/png;base64,${this.state.signature}`;
    }
    return this.props.initial;   
  }
  
  render() {
    const { label, isIssuable, onSubmitIssue, id, isCompleted, placeholder } = this.props;
    const color = 0x000000;
    const width = 5;
    const alpha = 0.5;
    console.log("CURRENT SIGNATURE STATE=>" + JSON.stringify(this.state.signature));
    
    return (
      <>
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        />

      <View style={styles.container}>
        <Text style={styles.label}>{label+" "}<Text style={{fontStyle: 'italic',color: '#002f6c',fontSize: 18,}}>{placeholder}</Text></Text>
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            this.setState({dialogVisible:true});
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        {Platform.OS === "ios"? (
          <View>
            <View style={styles.signature}>
              {this.state.show && (
                <SignaturePad
                  onError={this._signaturePadError}
                  onChange={this._signaturePadChange}
                  dataURL={this.getURL()}
                  ref="sig"
                  style={{
                    flex: 1,
                    backgroundColor: "white",
                    height: 140,
                  }}
                />
              )}
            </View>
            {this.state.signature != "0" && this.state.signature != "" && (
              <TouchableHighlight
                style={styles.buttonCreate}
                onPress={() =>
                  this.setState({ signature: "" }, () => this.clearSignature())
                }
              >
                <Text style={styles.buttonText}>Clear signature</Text>
              </TouchableHighlight>
            )}
          </View>
        ) : Platform.OS === "android"? (
          <View>
            {this.state.signature != "0" && this.state.signature != "" ? (
              <Image
                style={{ height: 200}}
                resizeMethod="resize"
                resizeMode="center"
                source={{
                  uri: this.state.signature.includes('data:image/')?this.state.signature:`data:image/png;base64,${this.state.signature}`,
                }}
              />
            ) : (
              <ExpoPixi.Signature
                style={{
                  height: 200,
                  borderColor: "#efefef",
                  borderWidth: 1,
                }}
                ref={(ref) => (this.sketch = ref)}
                onChange={async () => {
                  const { uri } = await this.sketch.takeSnapshotAsync({
                    format: "png",
                  });
                // console.log(await this.fileToBase64Helper(uri))
                  try {
                    const file = await FileSystem.readAsStringAsync(uri, {
                      encoding: "base64",
                    });
                    this.sendSignature(file);
                  } catch (e) {
                    console.error('error on signature >'+e.message);
                  }
                }}
                strokeColor={color}
                strokeWidth={width}
                strokeAlpha={alpha}
              />
            )}
            <TouchableHighlight
              underlayColor='#20B2AA33'
              style={styles.buttonCreate}
              onPress={() =>
                this.setState({ signature: "" }, () => this.clearSignatureAndroid())
              }
            >
              <Text style={styles.buttonText}>Clear signature</Text>
            </TouchableHighlight>
          </View>
        ):(
          <View>
            {this.state.signature != "0" && this.state.signature != "" && this.props.initial==this.state.signature ? (
              <Image
                style={{ height: 200}}
                resizeMethod="resize"
                resizeMode="center"
                source={{
                  uri: this.state.signature.includes('data:image/')?this.state.signature:`data:image/png;base64,${this.state.signature}`,
                }}
              />
            ) : (
              <SignatureCanvas ref={this.signatureRef} 
              minDistance={1}
      
                onEnd={() => {
                  const imageData = this.signatureRef.current.toDataURL()
                    
                  this.setState({ signature: imageData });
                  this.sendSignature(imageData)
                }}
                
                canvasProps={{height: 100,
                      width: window.width,
                      borderColor: "#efefef",
                      borderWidth: 1,}}/>
            )}
            <TouchableHighlight
              underlayColor='#20B2AA33'
              style={styles.buttonCreate}
              onPress={() =>
                this.setState({ signature: "" }, () => this.cancelSignature())
              }
            >
              <Text style={styles.buttonText}>Clear signature</Text>
            </TouchableHighlight>
          </View>
          
        )
        
        
        }
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 5,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  signature: {
    backgroundColor: "#fff",
    borderRadius: 5,
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    marginBottom: 10,
  },
  wrapper: {
    flexDirection: "column",
    alignItems: "center",
  },
  buttonCreate: {
    height: 60,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
    borderRadius: 12,
    borderColor: colors.primary,
    borderWidth: 1,
  },
  buttonText: {
    fontSize: 14,
    color: colors.primary,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
