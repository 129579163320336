import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, TouchableHighlight, TextInput, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import colors from '../../constants/colors';
import Modal from 'modal-react-native-web';
import { api } from "../../api";
import { setAttachment } from "../../actions/submissions";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible:false,
      pin:'',
      validPin:1234,
      isValidPin: false,
      showIncorrectPin: false,
      documentName:'',
      showInstructions: false,
    }
  }
  
  componentDidMount(){
    this.setState({isValidPin:false, showInstructions: false,})
  }
  
  uploadDocument(uri, name) {
    this.updateDocument(uri, name);
    return;
  }

  updateDocument(uri, name) {
    const { id, onChange, formId, setFormAttachment, mediaId} = this.props;
    let data='document';
    const dividedName = name?.split(".");
    const extFile = dividedName[dividedName.length-1];
    if(extFile != "doc"){
      setFormAttachment({
        data,
        uri,
        name,
        formId,
        componentId:id,
        mediaId,
      })
    }
    else
      alert(`Please upload the document using the latest word format (.docx) .`)
    
  }

  pickDocument =  async () => {
    let result = await DocumentPicker.getDocumentAsync();
  
    if(result.type=="success"){
      this.setState({documentName:result.name})  
      this.uploadDocument(result.uri, result.name); 
    }
  }

  attachmentViewer = ( media ) => {
    const mediaName = typeof media !== 'undefined' ? media.split("/")[4] : '';
    const mediaExt = mediaName !== '' ? mediaName.split(".")[1] : 'none';
    let mediaUrl = '';
    switch(mediaExt){
      case 'docx':
      case 'xlsx':
      case 'xls':
      case 'ppt':
      case 'pptx':
        mediaUrl = `https://docs.google.com/gview?url=${api + media}&embedded=true`;
        return (
          <iframe 
            style={{flex:0.5, justifyContent:'center'}}
            src={mediaUrl}>
          </iframe>
        );
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        mediaUrl = `${api + media}`;
        return(
          <div style={{flex:0.5, justifyContent:'center', maxHeight:400, overflow:'auto'}}>
            <img src={mediaUrl} width='100%' />
          </div>
        );
      default:
        mediaUrl = `${api + media}`;
        return(
          <iframe 
            style={{flex:0.5, justifyContent:'center'}}
            src={mediaUrl}>
          </iframe>
        );
    }
  } 

  modal(){
    const {modalVisible, pin, isValidPin, validPin, showIncorrectPin, documentName, showInstructions} = this.state;
    const { label, media, formId} = this.props;
    
    // if (Platform.OS == "web") {
       return (
        <Modal
        animationType="slide"
        visible={modalVisible}
        style={{flex:1, flexDirection: 'column', backgroundColor:"#F3F3F3",}}
      >
          <View style={{flex:1, }}></View>
          <View style={{flex:8, flexDirection: 'row', }}>
            <View style={{flex:1}}></View>
            <View style={{flex:6, justifyContent:'space-between', borderRadius:15, borderWidth:1, borderColor:colors.gray_darken_3}}>
            <Text style={{ 
              borderTopStartRadius:15,
              borderTopEndRadius:15, 
              borderWidth:0 ,
              backgroundColor: '#002f6c', 
              color:'#fff',
              flex:0.1, 
              fontSize: 20, 
              textAlign: 'center', 
              paddingTop:10,
              }}>{label} Media</Text>

            { 
              showInstructions && 
              this.attachmentViewer(media)
            }
            
            
            {
                typeof media !== 'undefined' &&
                <TouchableHighlight
                  style={styles.enterBtn}
                  onPress={() => {
                    this.setState({showInstructions: !showInstructions })
                    // const url = api + media;
                    // window.open(url, '_blank');
                  }}
                >
                  <Text style={{ color: colors.userCommandText }}>{showInstructions ? 'Hide' : 'Show'} Instructions</Text>
                </TouchableHighlight>
            }
            {
              documentName != "" &&
              <Text style={{ fontSize: 15, flex: 0.2 }}>Media to be uploaded: {documentName}</Text>
            }
          {
                !isValidPin &&
                <>
                  {
                    showIncorrectPin &&
                    <Text style={{ fontSize: 15, flex: 0.2, color: colors.error }}>Incorrect PIN, please verify it and try again</Text>
                  }
                  <TextInput
                    secureTextEntry={true}
                    value={pin}
                    style={{
                      flex:0.06,
                      borderColor: "#e7e7e7",
                      borderRadius: 5,
                      borderWidth: 1,
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontSize: 22,
                      fontWeight: "400",
                      height: 65,
                      paddingLeft: 10,
                      textAlign:'center',
                    }}
                    placeholder={"Security PIN"}
                    onChangeText={(val) => {
                      this.setState({ pin: val });
                    }}
                  />
                  <TouchableHighlight
                    style={styles.recordsCommand}
                    onPress={() => {
                      if (pin == validPin) {
                        this.setState({ isValidPin: true });
                      }
                      else {
                        this.setState({ showIncorrectPin: true });
                      }

                    }}
                  >
                    <Text style={{ color: colors.gray_darken_3, marginTop: 10,  marginBottom: 10 }}>Edit Instructions</Text>
                  </TouchableHighlight>
                </>
              }

              {
                isValidPin &&
                <TouchableHighlight
                  style={styles.recordsCommand}
                  onPress={this.pickDocument}
                >
                  <Text style={{ color: colors.primary, marginTop: 10,  marginBottom: 10 }}>Add Document</Text>
                </TouchableHighlight>
              }

              <TouchableHighlight
                style={styles.recordsCommand}
                activeOpacity={0.5}
                onPress={() => this.setState({ modalVisible: false })}
              >
                <Text style={{ color: colors.primary, marginTop: 10,  marginBottom: 10 }}>Close</Text>
              </TouchableHighlight>
            </View>
            <View style={{flex:1}}></View>
          </View>
          <View style={{flex:1}}></View>
         
      </Modal>);
         
    }
   
  
  render() {
    const { label, showSeparator, children, isLast, media } = this.props;
    const iconColor = typeof media !== 'undefined' ? 'green' : 'gray' 
    {/* return <View style={isLast ? styles.containerLast :styles.container}> */}
    return <View>
      {this.modal()}
      <View style={{flex:1, flexDirection:'row', justifyContent:'space-between', }}>
        <Text style={showSeparator ? styles.labelFirst :styles.label}>{label}</Text>    
        <TouchableOpacity onPress={()=> this.setState({modalVisible: true})} >
            <Icon name="help-box" style={{color:iconColor, fontSize:30, }}/>
        </TouchableOpacity>
      </View>
      {children}
    </View>;
  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
    }
  },
  (dispatch, props) => {
    return {
      setFormAttachment: (params) => dispatch(setAttachment(params)),
    };
  }
)(Section);

const styles = StyleSheet.create({
  recordsCommand: {  
    flex: 0.08,  
    flexDirection: "row",
    alignItems: "center",    
    justifyContent: "center",
    borderRadius:15,
    borderWidth: 0,    
    backgroundColor: colors.gray_darken_2, 
    marginLeft: 1, 
  }, 
  enterBtn: {  
    flex: 0.08,  
    flexDirection: "row",
    alignItems: "center",    
    justifyContent: "center",
    borderRadius:15,
    borderWidth: 0,    
    backgroundColor: colors.userCommand, 
    marginLeft: 1,   
  }, 
  input: {
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderWidth: 1,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "400",
    height: 65,
    paddingLeft: 10,
    width: '70%',             
  },
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderBottomWidth: 10,
    borderBottomColor: "#B3B7BC",      
    flex:1,
  },
  containerLast: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,    
    flex:1,
  },
  label: {
    wordBreak: 'break-all',
    fontSize: 22,
    color: colors.primary,
    fontWeight: "600",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 5,    
  },
  labelFirst: {
    wordBreak: 'break-all',
    fontSize: 22,
    color: colors.primary,
    fontWeight: "600",
    paddingBottom: 10,    
    paddingLeft: 5,    
  },
});
