import 'react-native-gesture-handler';
import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Login from './components/login';
import Register from './components/register';
import MainView from './components/mainview';
import RecordsView from './components/records';
import TaskDetail from './components/taskDetail';
import FormView from './components/form';
import NewTask from './components/newTask';
import GroupView from './components/groupView';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import colors from "./constants/colors";
import { logout } from "./actions/login";
import { 
  Image, View, TouchableOpacity, Text, NativeModules, Platform
 } from 'react-native';
 import { MaterialCommunityIcons } from "@expo/vector-icons";
 import alerts from './components/components/alert';
import TitleBar from "./components/widgets/NTitlebar";
import { useSelector, useDispatch } from "react-redux";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({      
      serializableCheck: false,
    }),
});

function LoginScreen({ navigation }) {
    return (<Login navigation={navigation} />);
}
function RegisterScreen({ navigation }) {
  return (<Register navigation={navigation} />);
}
function MainViewScreen({ navigation }) {
  return (<MainView navigation={navigation} />);
}
function RecordsViewScreen({ navigation }) {
  return (<RecordsView navigation={navigation} />);
}

function TaskDetailScreen({ navigation }) {
  return (<TaskDetail navigation={navigation} />);
}
function FormViewScreen({ route, navigation }) {
  return (<FormView navigation={navigation} formId={route.params.formId} title={route.params.title} isSubmission={route.params.submission} newReport={route.params.newReport} task_id={route.params.task_id} log_group_id={route.params.log_group_id} />);
}
function NewTaskScreen({ navigation }) {
  return (<NewTask navigation={navigation} />);
}
function GroupViewScreen({ navigation }) {
  return (<GroupView navigation={navigation} />);
}

function LogoTitle() {
  return (
    <View style={{display:'flex', justifyContent: 'center', alignItems: 'center',}}>
      <Image
        style={{ width: 150, height: 50}}
        source={require("../assets/SOFTHEMP_white.png")}        
      />
    </View>
  );
}

function Logout({ navigation }) {
  const dispatch = useDispatch();
  return (
    <View style={{display:'flex', flexDirection: "row"}}>
      <View style={{display:'flex', flex:1, justifyContent: "flex-end", flexDirection: "row", alignItems: 'flex-end', }}>
        <Text style={{color:'white', fontWeight: 'bold', textAlign: 'center',fontSize:18}}>Dashboard</Text>
      </View>
    <View style={{display:'flex', flex:1, justifyContent: 'flex-end', flexDirection: "row", alignItems: 'flex-end'}}>
      <TouchableOpacity 
      style={{display:'flex', flex:1, justifyContent: 'flex-end', flexDirection: "row"}}
      onPress={() => {
        alerts(
          "Logout",
          "Are you sure to logoff your account?",
          [
            {
              text: "Ok",
              onPress: () => {
                if (Platform.OS === 'web') {
                 location.reload();
                }else{
                  dispatch(logout());
                  NativeModules.DevSettings.reload();
                }
              },
              
            },
            {
              text: "Cancel",
              onPress: () => {
              },
              style: "cancel",
            },
          ]
        );
      }}
      
      >
          <Text style={{ color:"white",}}>Logout</Text>
          <MaterialCommunityIcons
                name="account-circle"
                size={22}
                color="#fff"
                style={{height: 24,
                  width: 24,}}
              />
        </TouchableOpacity> 
    </View>
    </View>
  );
}
const Stack = createStackNavigator();

export default function App() {
  return (
    <Provider store={store}> 
      <NavigationContainer>                  
            <Stack.Navigator>            
              <Stack.Screen name="Login" options={{ headerTitle: props => <LogoTitle {...props} />, title: "Login", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold' 
            
            } }}   component={LoginScreen} />          
              <Stack.Screen name="Register" options={{ title: "Register", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            } }}   component={RegisterScreen} />
              <Stack.Screen name="MainView" options={{ headerTitle: props => <Logout {...props} />, title: "Records", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            }, headerLeft: null }}   component={MainViewScreen} />
              <Stack.Screen name="RecordsView" 
                options={{ 
                  headerTitle: props => <TitleBar title="SUBMITTED FORMS" {...props} /> ,
                  headerTintColor: '#ffffff',
                  headerStyle:{
                    backgroundColor: colors.primary,
                  }
                }}
                component={RecordsViewScreen} 
              />
              <Stack.Screen name="TaskDetail" options={{ title: "", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            }}}   component={TaskDetailScreen} />            
              <Stack.Screen name="FormView" options={({ route }) => ({ title: route.params.title, headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            }, })}   component={FormViewScreen} /> 
              <Stack.Screen name="NewTask" options={{ title: "", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            }}}   component={NewTaskScreen} />
              <Stack.Screen name="GroupView" options={{ title: "Groups", headerStyle: {
            backgroundColor: colors.primary,}, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',
            
            }}}   component={GroupViewScreen} />                                                                                                             
            </Stack.Navigator>   
      </NavigationContainer>  
    </Provider>
  );
}