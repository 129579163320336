import React, { Component } from 'react';
import { View, StyleSheet, Text, TextInput, TouchableOpacity} from 'react-native';
import Modal from 'react-native-modalbox';
import colors from "../../constants/colors";

export default class IssueDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment:"",
        };
    }

	render() {
    const {visible, onSubmitIssue, id, handleClose, label } = this.props;
    const {comment} = this.state;
		return <Modal 
                    style={[styles.modal, styles.modal3]} 
                    swipeToClose={false} 
                    position={"top"} 
                    isOpen={visible}    
            >
                    <Text>New Issue</Text>
                    <View style={{flex:1, flexDirection:'row', alignItems: 'flex-start', justifyContent: 'center',}}>
                      <TextInput
                        value={this.state.comment}
                        style={styles.input}
                        onChangeText={(val) => {
                          this.setState({ comment: val });
                        }}
                      />
                    </View>
                    <View style={{flex:1.0, flexDirection:'row', alignItems: 'stretch', justifyContent: 'space-between',}}>
                      <TouchableOpacity
                        style={styles.button}            
                        onPress={ () => {
                          onSubmitIssue({id, comment, label}); 
                          handleClose();
                        }}
                      >
                        <Text style={styles.buttonText}>
                          Save
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.button}            
                        onPress={ () => handleClose() }
                      >
                        <Text style={styles.buttonText}>
                          Close
                        </Text>
                      </TouchableOpacity>
                    </View>
	            </Modal>;
	}
}

const styles = StyleSheet.create({
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.gray_darken_2,
    borderRadius: 5,
    flex: 0.8,
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 10,
    height: 50,
  },
  buttonText: {
    color: colors.primary,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    height: 40,
    flex: 0.9,
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  wordTwo: {
    fontWeight: '300',
  },
  dialogTitle: {
    color: '#444',
    fontSize: 32,
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontWeight: '600',
    fontFamily: 'Roboto',
  },
  modal: {
    flex:1,
    flexDirection:'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modal3: {
    height: 200,
    width: 500
  },
  dialogTableR: {
    textAlign: 'center',
    marginLeft: 5,
    fontSize: 10,
  },
  submissionHeader: {
  	fontWeight: '600',
  	fontSize: 14,
  }
});
