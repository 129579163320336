import { createAction } from "redux-actions";
import { GET_LOG_GROUPS, SET_LOG_GROUP_SELECTED, SET_IS_LOG_GROUP, GET_LOG_GROUPS_SUBMISSIONS, SHOULD_RELOAD_LGSUBMISSIONS, GET_GROUP_DETAILS, SET_LOG_GROUPS_SUBMISSIONS, SET_LOG_GROUPS_SUBMISSIONS_FALSE} from "../constants/actions";
import { getLogGroups, getLogGroupsSub, loadGroupDetail } from "../api";
import { NavigationActions } from 'react-navigation';
/*import { Actions } from "react-native-router-flux";*/

export function getLogGroupsAction() {
    return (dispatch, getState) => {
        dispatch(
            createAction(GET_LOG_GROUPS)(
                getLogGroups(dispatch, getState).then((response) => {
                    return response;
                })
            )
        );
    };
}
export function getGroupDetail(id) {
    /*return (dispatch, getState) => {
        dispatch(
            createAction(GET_GROUP_DETAILS)(
                loadGroupDetail(dispatch, getState, id).then((response) => {
                    //Actions.groupView();
                    return response;
                })
            )
        );
    };*/

    return (dispatch, getState) => {
        
        loadGroupDetail(dispatch, getState, id).then(
          (response) => {        
            dispatch(createAction(GET_GROUP_DETAILS) (response)); 
            return response;
          });
        
      };
}

export function setLogGroupSelected(data) {
    return (dispatch, getState) => {
        dispatch(createAction(SET_LOG_GROUP_SELECTED)(data));
    };
}
export function setIsLogGroup(data) {
    return (dispatch, getState) => {
        dispatch(createAction(SET_IS_LOG_GROUP)(data));
    };
}
export function shouldReloadLG(data) {
    return (dispatch, getState) => {
        dispatch(createAction(SHOULD_RELOAD_LGSUBMISSIONS)(data));
    };
}
export function getLogGroupsSubAction(id) {
    return (dispatch, getState) => {
                getLogGroupsSub(dispatch, getState, id).then((response) => {
                    dispatch(createAction(GET_LOG_GROUPS_SUBMISSIONS)(response.data));
                    setTimeout(()=>{
                        dispatch(createAction(SET_LOG_GROUPS_SUBMISSIONS)())        
                        return response.data;
                    },5000);
                });   
    };
}

export function setLogGroupsSubAction() {
    return (dispatch, getState) => {
        dispatch(createAction(SET_LOG_GROUPS_SUBMISSIONS)()) 
    };           
}

export function setLogGroupsSubActionFalse() {
    return (dispatch, getState) => {
        dispatch(createAction(SET_LOG_GROUPS_SUBMISSIONS_FALSE)()) 
    };           
}