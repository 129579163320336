import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
    };
  }

  getStyle() {
    var style = {
      fontSize: 20,
      color: "#424242",
      fontWeight: "400",
      height: 100,
      borderColor: "#e7e7e7",
      borderWidth: 1,
      borderRadius: 5,
      paddingLeft: 10,
      paddingTop: 10,
      fontFamily: "Roboto",
      dialogVisible: false,
    };

    if (this.props.label == "TK Trainer Comments") {
      style["color"] = "#d00";
      style["borderColor"] = "#d00";
    }

    return style;
  }

  render() {
    const { value } = this.state;
    const { label, onChange, id,  isIssuable, onSubmitIssue, isCompleted, placeholder } = this.props;
    return (
      <>
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        />

      <View style={styles.container}>
        <Text style={styles.label}>{label}</Text>
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            this.setState({dialogVisible:true});
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        <TextInput
          value={value}
          style={this.getStyle()}
          multiline={true}
          placeholder={placeholder}
          onChangeText={(val) => {
            this.setState({ value: val });
            onChange(id, val);
          }}
        />
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
