import React, { Component } from "react";
import {
  Alert,
  View,
  ScrollView,
  Text,
  StyleSheet,
  FlatList,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  Platform,
  ActivityIndicator,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import SignaturePad from "react-native-signature-pad";
import SignatureCanvas from 'react-signature-canvas';
import colors from "../constants/colors";
import { connect } from "react-redux";
import { Actions } from "react-native-router-flux";
import moment from "moment-timezone";
import { navigateForm, navigateSubmission } from "../actions/menu";
import * as Device from "expo-device";
import { getLogGroupsSubAction, shouldReloadLG, setLogGroupsSubActionFalse, setLogGroupSelected, setIsLogGroup } from "../actions/loggroups";
import { signTaskAction } from "../actions/tasks";
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import * as FileSystem from "expo-file-system";
import ExpoPixi from "expo-pixi";
import alerts from './components/alert';

var isTablet = false;
var numColumns = 1;

class GroupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      isLoading: true,
      signature: "",
      show: true,
      colaboratorName: "",
      isSendingData: false,
      executeLogAction: false,
      taskSelected:false,
    };
    this.signatureRef = React.createRef();
  }

  componentDidMount() {
    this.getDeviceType();
  }
  componentDidUpdate(prevProps, prevState) {
    /*if (prevProps.syncState !== this.props.syncState && this.props.syncState == "syncSuccess") {
      this.props.getLogGroupsSubAction(this.props.taskSelected._id);
    }*/
    if(!this.state.executeLogAction && this.props.logGroupSubmissions.length==0){
      this.props.getLogGroupsSubAction(this.props.taskSelected._id);
      this.setState({ executeLogAction: true});
    }
    if (prevProps.logGroupSelected !== this.props.logGroupSelected) {
      this.props.getLogGroupsSubAction(this.props.taskSelected._id);
      this.setState({ isLoading: false});
    }
    
      if(this.props.submissionIndicator){
        
          this.props.getLogGroupsSubAction(this.props.taskSelected._id);
          
      }
      
      this.props.setLogGroupsSubActionFalse();
      
    
  }
  render() {
    const { isLoading } = this.state;
    const color = 0x000000;
    const width = 5;
    const alpha = 0.5;
    
    return (
      <ScrollView style={styles.container}>
        {isLoading ? (
          <View
            style={{
              flex: 1,
              padding: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
        ) : (
          <View style={{
            flexDirection: "row",
          }}>
            <View style={{ flex: 1, }}/>
            <View style={{ flex: 1,backgroundColor: '#fff', padding: 50, borderRadius: 20, marginTop: 10}}>
          <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <Text
              style={[
                styles.title,
                { fontSize: isTablet ? 24 : 16, marginTop: isTablet ? 20 : 10 },
              ]}
            >
              Report category
            </Text>
            <Text
              style={[
                styles.title,
                { fontSize: isTablet ? 20 : 14, marginTop: isTablet ? 10 : 5 },
              ]}
            >
              Which report do you want to complete?
            </Text>
            {

!this.props.logGroupSelected.forms?
  alerts(
    "This Log has been erased, please check records on Admin",
    "",
    [
      {
        text: "Ok",
        onPress: () => {
          this.props.setLogGroupSelected({});
          this.props.setIsLogGroup(false);  
          this.props.navigation.navigate('RecordsView');
        },
      },
      {
        text: "Cancel",
        onPress: () => {
          this.props.setLogGroupSelected({});
          this.props.setIsLogGroup(false);  
          this.props.navigation.navigate('RecordsView');
        },
        style: "cancel",
      },
    ]
    ):this.formatData(numColumns).map((item,index)=>{                
      return this._renderItem({index,item});
      })
    }
                        
            <Text
              style={[
                styles.title,
                { fontSize: isTablet ? 24 : 16, marginTop: isTablet ? 20 : 10 },
              ]}
            >
              Entries
            </Text>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
              }}
            >
              {this.props.logGroupSubmissions.length > 0 ? (
                this.renderProducts()
              ) : (
                <View>
                  <Text
                    style={[
                      styles.title,
                      {
                        fontSize: isTablet ? 18 : 14,
                        marginTop: isTablet ? 10 : 5,
                      },
                    ]}
                  >
                    There are no records for this log yet.
                  </Text>
                </View>
              )}
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#f3f3f3",
                  marginVertical: 10,
                }}
              ></View>
                            <TextInput
                value={this.state.colaboratorName}
                style={styles.input}
                placeholder="Verified by: (enter name of person in charge)"
                onChangeText={(val) => {
                  this.setState({ colaboratorName: val });
                }}
              />
              <Text
                style={[
                  styles.title,
                  {
                    fontSize: isTablet ? 18 : 14,
                    marginTop: isTablet ? 10 : 5,
                  },
                ]}
              >
                Sign your report
              </Text>
              {Platform.OS === 'ios'? (
                <View>
                  <View style={styles.signature}>
                    {this.state.show && (
                      <SignaturePad
                        onError={this._signaturePadError}
                        onChange={this._signaturePadChange}
                        dataURL={this.state.signature}
                        ref="sig"
                        style={{
                          flex: 1,
                          backgroundColor: 'white',
                          height: 140,
                        }}
                      />
                    )}
                  </View>
                  {this.state.signature != "" && (
                    <TouchableHighlight
                      style={styles.buttonCreate}
                      onPress={() =>
                        this.setState({ signature: "" }, () =>
                          this.clearSignature()
                        )
                      }
                    >
                      <Text style={styles.buttonText}>Clear signature</Text>
                    </TouchableHighlight>
                  )}
                </View>
              ):Platform.OS === 'android'?(
                <View>

                  <ExpoPixi.Signature
                    style={{
                      height: 200,
                      borderColor: "#efefef",
                      borderWidth: 1,
                    }}
                    ref={(ref) => (this.sketch = ref)}
                    onChange={async () => {
                      const { uri } = await this.sketch.takeSnapshotAsync({
                        format: "png",
                      });
                      // console.log(await this.fileToBase64Helper(uri))
                      try {
                        const file = await FileSystem.readAsStringAsync(uri, {
                          encoding: "base64",
                        });
                        this.setState({
                          signature: file
                        }, ()=> console.log(this.state.signature));
                      } catch (e) {
                        console.error(e.message);
                      }
                    }}
                    strokeColor={color}
                    strokeWidth={width}
                    strokeAlpha={alpha}
                  />
                  <TouchableHighlight
                    underlayColor='#20B2AA33'
                    style={styles.buttonCreate}
                    onPress={() =>
                      this.setState({ signature: "" }, () => this.clearSignatureAndroid())
                    }
                  >
                    <Text style={styles.buttonText}>Clear signature</Text>
                  </TouchableHighlight>
                
                </View>
                ):
                <View>
                <SignatureCanvas ref={this.signatureRef}   
                onEnd={() => {
                  const imageData = this.signatureRef.current.toDataURL()  
                  this.setState({ signature: imageData });
                  
                }}
                backgroundColor='#ffffff'
                canvasProps={{height: 200,
                      borderColor: "#efefef",                      
                      borderWidth: 1,}}
                      
                style={{backgroundColor: "#ffffff",}}
                      />
                
                <TouchableHighlight
                    underlayColor='#20B2AA33'
                    style={styles.buttonCreate}
                    onPress={() =>
                      this.setState({ signature: "" }, () => this.cancelSignature())
                    }
                  >
                    <Text style={styles.buttonText}>Clear signature</Text>
                  </TouchableHighlight>
              </View>
              
              }
              <TouchableOpacity onPress={() => {

              alerts(
                "Record Complete",
                "Are you sure you want to approve this log and all associated entries?",
                [
                  {
                    text: "Yes",
                    onPress: () => {
                      this.setState({isSendingData: true});
                      let sign = {};
                      sign.signature = this.state.signature;
                      sign.colaborator_name = this.state.colaboratorName;
                      sign.task_id = this.props.taskSelected._id;
                      sign.signed_at = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");
                      this.props.signTaskAction(sign);
                      this.props.navigation.navigate('MainView');
                    },
                  },
                  {
                    text: "Cancel",
                    onPress: () => {
                    },
                    style: "cancel",
                  },
                ]
              );
                
              }} style={styles.buttonSubmit} disabled={this.state.isSendingData}>
                {this.state.isSendingData ? (
                    <ActivityIndicator size="large" color="white" style={{padding: 15}} />
                  ): (
                    <Text
                      style={[
                        styles.commandText,
                        {
                          textAlign: "center",
                          fontSize: isTablet ? 24 : 16,
                        },
                      ]}
                    >
                      Approve Record
                    </Text>
                  )}
              </TouchableOpacity>

             
            </View>
          </View>
        </View>
        <View style={{ flex: 1 }}/>
        </View>
        )}
      </ScrollView>
    );
  }

  _renderItem = ({ item, index }) => {
    if (item.isEmpty === true) {
      return (
        <View
          style={[
            styles.renderButton,
            {
              backgroundColor: "transparent",
              paddingVertical: isTablet ? 40 : 5,
            },
          ]}
        ></View>
      );
    }
    
    return (
      <TouchableOpacity
        key={item._id}
        onPress={() => {
          this.props.setIsSubmission(false);
          this.props.setSubmissionSelected(null);
          
          this.props.navigation.navigate('FormView', { formId: item._id, title: item.name, newReport:true, submission:false , task_id: this.props.taskSelected._id, log_group_id:this.props.logGroupSelected.id});    
          /*this.props.selectForm(
            item.id,
            item.name,
            this.props.previousSubmissions,
            this.props.taskSelected._id,
            this.props.logGroupSelected.id
          );*/
        }}
        style={[
          styles.renderButton,
          {            
            paddingVertical: isTablet ? 40 : 5,
          },
        ]}
      >
        <Text
          style={[
            styles.renderText,
            {
              fontSize: isTablet ? 24 : 16,
            },
          ]}
        >
          {item.name}
        </Text>
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            borderBottomStartRadius: 8,
            borderTopEndRadius: 8,
          }}
        >
          <MaterialCommunityIcons
            name="plus"
            size={isTablet ? 36 : 26}
            color={colors.primary}
          />
        </View>
      </TouchableOpacity>
    );
  };

  findProductName(item){    
    let itemId = "";
    var BreakException = {};
    try {
      item.components.forEach((component)=>{
        switch(component.type){
          case 'inputlabel':
          case 'input':
          case 'dropdown':
          case 'textarea':
          itemId = component.id;
          throw BreakException;
        }
      });
    } catch (e){}   
    if(typeof item.submission[itemId] === 'undefined')
      return item.name;    
    else
      return item.submission[itemId];
  }

  getTimeConverted(date) {
    let d = moment.tz(date, "UTC");  
    if( moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
      return "Today at " + moment.tz(d, moment.tz.guess(true)).format("H:mm");
    else
      return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
  }

  renderProducts() {
    
    let products = [];    
    this.props.logGroupSubmissions.forEach((item, index) => {
      
      products.push(
        <View key={index} style={styles.rowReport}>
          <View>
            <Text
              style={[
                styles.textReport,
                {
                  fontSize: isTablet ? 18 : 14,
                  alignSelf: "flex-start",
                },
              ]}
            >
              {this.findProductName(item)}
            </Text>
            { item.status == 'edit_required' && (
              <Text
                style={[styles.textReport, {
                  fontSize: isTablet ? 18 : 14,
                  alignSelf: "flex-start",
                  color: "#E34537",
                }]}
              >
                This entry requires edition</Text>
            )}
            <Text
              style={[
                styles.textReport,
                {
                  fontSize: isTablet ? 18 : 14,
                  color: "#ccc",
                  alignSelf: "flex-start"
                },
              ]}
            >
              {this.getTimeConverted(item.updated_at)}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              style={{
                backgroundColor: colors.gray_darken_2,
                borderRadius: 120,
                marginHorizontal: 5,
              }}
              onPress={() => {
                this.props.setSubmissionSelected({ ...item });
                this.props.setIsSubmission(true);
                this.props.navigation.navigate('FormView', { formId: item.id, title: item.name, newReport:false, submission:item.submission , task_id: this.props.taskSelected._id, log_group_id:this.props.logGroupSelected.id});    
                /*this.props.navigateSubmission(
                  item.id,
                  item.name,
                  false,
                  this.props.logGroupSelected.id
                );*/
              }}
            >
              <MaterialCommunityIcons
                name="pencil"
                size={isTablet ? 30 : 20}
                color={colors.primary}
                style={{
                  padding: 10,
                  borderRadius: 120,
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    });
    return products;
  }
  formatData = (numColumns) => {
    let data = this.props.logGroupSelected.forms;
    const numberOfRows = Math.floor(data.length / numColumns);

    let numberOfElementsLastRow = data.length - numberOfRows * numColumns;

    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow) {
      data.push({
        text: "empty",
        id: "empty",
        isEmpty: true,
      });
      numberOfElementsLastRow++;
    }
    return data.filter(x=> x != null);    
  };

  getDeviceType = async () => {
    let device = await Device.getDeviceTypeAsync();
    if (device === Device.DeviceType.TABLET) {
      isTablet = true;
    } else {
      isTablet = false;
    }
    numColumns = isTablet ? 3 : 1;
  };
  signatureChanged = (paths) => {
    this.setState({ signature: paths, disabled: false });
  };

  _signaturePadError = (error) => {
    //console.error(error);
  };

  _signaturePadChange = ({ base64DataUrl }) => {
    this.setState({
      signature: base64DataUrl,
      disabled: false,
    });
  };

  clearSignature() {
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }
  clearSignatureAndroid(){
    
    if (this.sketch.stage&&this.sketch.stage.children.length > 0) {
      this.sketch.stage.removeChildren();
      this.sketch.renderer._update();
    }
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  cancelSignature(){
    
    this.signatureRef.current.clear();
    
  }

  handleSignature = signature => {
    
    this.setState({ signature });
  };
 
  handleEmpty(){
    console.log('Empty');
  }
 
  handleClear(){
    console.log('clear success!');
  }
 
  handleEnd(ref){
      
      ref.current.readSignature();
  }
}


const mapStateToProps = (state) => {
  return {
    tasks: state.tasks.get("tasks"),
    filter: state.tasks.get("filter"),
    taskSelected: state.tasks.get("taskSelected"),
    reload: state.tasks.get("reload"),
    archiveRecently: state.tasks.get("archiveRecently"),
    previousSubmissions: state.submissions.get("previousSubmissions"),
    token: state.user.get("authToken"),
    logGroupSelected: state.loggroups.get("logGroupSelected"),
    logGroupSubmissions: state.loggroups.get("logGroupSubmissions"),
    shouldReload: state.loggroups.get("shouldReload"),
    submissionIndicator:state.loggroups.get("submissionIndicator"),
    
    /*syncState: state.sync.get("state"),*/
  };
};

export default connect(mapStateToProps, (dispatch, props) => {
  return {
    selectForm: (id, name, previousSubmissions, task_id, log_group_id) => {  
      this.props.navigation.navigate('FormView', { formId: id, title: name, newReport:false, submission: true, task_id: task_id, log_group_id:log_group_id});    
      //dispatch(navigateForm(id, name, true, task_id, log_group_id));      
    },
    setLogGroupsSubActionFalse: () => {
      dispatch(setLogGroupsSubActionFalse());
    },
    getLogGroupsSubAction: (id) => {
      
      dispatch(getLogGroupsSubAction(id));
    },
    shouldReloadLG: (value) => {
      dispatch(shouldReloadLG(value));
    },
    setIsSubmission: (value) => {
      dispatch(setIsSubmission(value));
    },
    setSubmissionSelected: (value) => {
      dispatch(setSubmissionSelected(value));
    },
    navigateSubmission: (id, name, newReport, log_group_id) => {
      dispatch(navigateSubmission(id, name, newReport, log_group_id));
    },
    signTaskAction: (signature) => {
      dispatch(signTaskAction(signature));
    },
    setLogGroupSelected: (data) => {
      dispatch(setLogGroupSelected(data));
    },
    setIsLogGroup: (data) => {
      dispatch(setIsLogGroup(data));
    },
  };
})(GroupView);

const styles = StyleSheet.create({
  buttonSubmit: {
    flex: 1,
    borderRadius: 4,
    flexDirection: "row",
    /*textAlign: "center",*/
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    backgroundColor: colors.orange,
    paddingVertical: isTablet ? 40 : 5,
  },
  input: {
    borderColor: "#e7e7e7",
    backgroundColor: "#FFF",
    borderRadius: 5,
    borderWidth: 1,
    marginVertical: 10,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "400",
    height: 65,
    paddingLeft: 10,
  },
  buttonCreate: {
    height: 60,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
    borderRadius: 12,
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
  },
  buttonText: {
    fontSize: 14,
    color: colors.primary,
  },
  container: {
    flex: 1,
    backgroundColor: "#f6f6f6",
  },
  renderButton: {
    borderRadius: 12,
    backgroundColor: colors.gray_darken_2,
  },
  renderText: {
    fontFamily: "Roboto-Bold",
    color: colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  commandText: {
    fontFamily: "Roboto-Bold",
    color: "white",
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  rowReport: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 8,
    flex: 1,
    marginVertical: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  signature: {
    backgroundColor: "#fff",
    borderRadius: 5,
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    marginBottom: 10,
  },
  textReport: {
    fontFamily: "Roboto-Medium",
    alignSelf: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  title: {
    fontFamily: "Roboto-Bold",
    color: "#888",
    marginBottom: 10,
  },
});
