import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    View,
    StyleSheet,
    TouchableOpacity,
    Text
 } from 'react-native';
import { 
    recordInProgress, 
    recordEditRequired,
    recordComplete,
    recordApproved,
} from "../../actions/records";
import {   
  removeTaskAction, getTasks,
} from "../../actions/tasks";
import LottieView from 'lottie-react-native';
import colors from "../../constants/colors";
import Animations from "../animations";

export class RecordActions extends Component {
    constructor(props){
        super(props);
        this.state ={           
        };
    }
    showCommands(){  
        const {record,statusLoading,taskSelected} = this.props;        
        const isLog = !(typeof taskSelected.log_group_id === 'undefined');
        if(!isLog && (record == null || typeof record.status === 'undefined' || record.status==false|| record.status == 'pending' || record.status == 'signed' || record.status == "in_progress" || record.status == "edit_required" || record.status == "ready_for_review" || record.status == "ready_for_approval" || record.status == "rejected")){
         return (
           <View>
             <View style={styles.submitContainer}>
             <TouchableOpacity
               style={styles.button}               
               onPress={() => {
                   if(!statusLoading){
                     if(record == null){
                       this.props.setCompleteStatus();
                       this.props.removeTask({task_id: this.props.taskSelected._id});
                       this.props.getTasks();
                     }else{
                      this.props.recordComplete(record);
                      this.props.submitForm();                      
                      this.props.removeTask({task_id: record.task_id});
                      this.props.getTasks();
                     }                                 
                   }     
               }}>
              <View style={{display: 'flex', flexDirection:'row'}}>
              {statusLoading ?
               <Text style={styles.buttonText2}>
                 Record is complete
               </Text>:
               <Text style={styles.buttonText}>
               Record is complete
             </Text>
                }
               {statusLoading ? <Animations animationfile='loading-dots' /> : null}
               </View>
             </TouchableOpacity>
             </View>            
           </View>
         );
        }
     
        if(!isLog && record.status == "complete"){
         return (
           <View>
              <View style={styles.submitContainer}>
             <TouchableOpacity
               style={styles.button}
               underlayColor={"#50C5BE"}
               onPress={() => {
                   if(!statusLoading){
                    this.props.recordEditRequired(record);
                    this.props.submitFormSave();
                   }          
                 
               }}
             >
               <View style={{display: 'flex', flexDirection:'row'}}>
                  {statusLoading ?
                  <Text style={styles.buttonText2}>
                    Edit required
                  </Text>:
                  <Text style={styles.buttonText}>
                  Edit required
                </Text>
                    }
                  {statusLoading ? <Animations animationfile='loading-dots' /> : null}
               </View>
             </TouchableOpacity>       
             </View>  
             {record.status != "approved"?
               <View style={styles.submitContainer}>
               <TouchableOpacity
                 style={styles.buttonSecondary}
                 underlayColor={"#50C5BE"}
                 onPress={() => {     
                     if(!statusLoading){
                        this.props.recordApproved(record);
                        this.props.submitForm();
                     }               
                 }}
               >
                 <View style={{display: 'flex', flexDirection:'row'}}>
                  {statusLoading ?
                    <Text style={styles.buttonTextSecondary2}>
                      Approve record
                    </Text>:
                    <Text style={styles.buttonTextSecondary}>
                    Approve record
                  </Text>
                      }
                  
                  {statusLoading ? <Animations animationfile='loading-dots' /> : null}
                 </View>
               </TouchableOpacity>       
               </View> : null 
             }             
           </View>
         );
        }

        if(isLog || record.status == 'archived' || record.status == 'approved'){
          return null;
        }
         
    }
    
    render() {        
        return (
            this.showCommands()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        statusLoading: state.records.get("statusLoading"),
        taskSelected: state.tasks.get("taskSelected")
    }    
};

export default connect(mapStateToProps,(dispatch, props)=> {
    return {
        recordInProgress : (item) => {
            dispatch(recordInProgress(item))
        },     
        recordEditRequired : (item) => {
            dispatch(recordEditRequired(item))
        }, 
        recordComplete : (item) => {
            dispatch(recordComplete(item))
        }, 
        recordApproved : (item) => {
            dispatch(recordApproved(item))
        }, 
        removeTask : (item) => {
          dispatch(removeTaskAction(item))
      }, 
      getTasks: () => {
        dispatch(getTasks());
      },
    }   
})(RecordActions)


const styles = StyleSheet.create({
    submitContainer: {
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: 20,
        paddingHorizontal: 10,
    },
    buttonText: {
        color: colors.userCommandText,
        fontSize: 18,
        fontFamily: "Roboto",
    },
    buttonText2: {
      color: colors.userCommandText,
      fontSize: 18,
      fontFamily: "Roboto",
      paddingTop: 15,
  },
    buttonTextSecondary: {
      color: colors.commandSecondaryText,
      fontSize: 18,
      fontFamily: "Roboto",
    },
    buttonTextSecondary2: {
      color: colors.commandSecondaryText,
      fontSize: 18,
      fontFamily: "Roboto",
      paddingTop: 15,
    },
    button: {
        alignItems: "center",
        backgroundColor: colors.userCommand,
        borderRadius: 5,
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        height: 60,
    },
    buttonSecondary: {
      alignItems: "center",
      backgroundColor: colors.commandSecondary,
      borderRadius: 5,
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      padding: 10,
      height: 60,
  },
    loadingAnimation: { 
        height: 30,
        width: 30,
        position: "absolute",
        right: 10,     
    },
});