import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import {Alert} from 'react-native';
import { 
     RECORD_GET_REQUEST,
     RECORD_INPROGRESS_RESPONSE,
     RECORD_EDITREQUIRED_RESPONSE,
     RECORD_READYFORREVIEW_RESPONSE,
     RECORD_READYFORAPPROVAL_RESPONSE,
     SET_RECORD_STATUS_LOADING,
     SET_RECORDS_LOADING,
     ADD_DATA_TO_LOGRECORDS,
} from '../constants/actions';

const initialState = {
    records: [],
    logRecords:[],
    statusLoading: false,
    recordsLoading: false,
    lastFormUpdated: null,
};

export default handleActions({
  [ADD_DATA_TO_LOGRECORDS] : (state, action) =>{
    return state.set('logRecords', action.payload);
  },
  [SET_RECORD_STATUS_LOADING]: (state,action) => {
    return state.set('statusLoading',action.payload);
  },
  [SET_RECORDS_LOADING]: (state,action) => {
    return state.set('recordsLoading',true);
  },
  [RECORD_GET_REQUEST]: (state, action) => {
    const dateNow = new Date();
    return state.set('records', action.payload)
                .set("lastFormUpdated",dateNow)
                .set('recordsLoading',false);    
  },
  [RECORD_INPROGRESS_RESPONSE]: (state, action) => {
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);  
    }else return state.set('statusLoading',false);  
  },
  [RECORD_EDITREQUIRED_RESPONSE]: (state, action) => {    
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);    
    }else return state.set('statusLoading',false);
  },
  [RECORD_READYFORREVIEW_RESPONSE]: (state, action) => {   
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);    
    }else return state.set('statusLoading',false);
  },
  [RECORD_READYFORAPPROVAL_RESPONSE]: (state, action) => {
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);
    }else return state.set('statusLoading',false);
  
    
  },
}, Immutable.fromJS(initialState));