import React, { Component } from "react";
import { StyleSheet, View, Text, Platform, TouchableOpacity, TouchableHighlight } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import DatePicker from "rmc-date-picker";
import PopPicker from 'rmc-date-picker/lib/Popup';
import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import moment from "moment-timezone";
import NewIssue from "../dialogs/newIssue";
import colors from "../../constants/colors";

export default class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: moment.tz(this.props.initial, moment.tz.guess(true)).toDate(),
      showDate: false,
      showTime: false,
      showDatePicker: false,
      currentMode: "datetime",
      value2: new Date(),
      showModal:false,
      currentMode: "datetime",
      dialogVisible: false,

    };
  }

  renderDateTimePicker(){
   const { label, onChange, id, placeholder } = this.props;
   return <DateTimePicker
            value={this.state.value}
            mode={this.state.currentMode}
            placeholder={placeholder}
            onChange={(event, date) => {             
                if(date){                       
                  if(this.state.currentMode == "datetime" && Platform.OS !== "ios" ){                      
                    this.setState({ value: date, currentMode: "time" });                      
                  }else{
                    this.setState({value: date, currentMode: "datetime",showDatePicker: false});                    
                  }
                  onChange(id, moment(date).format("YYYY-MM-DD HH:mm"));
                }else{
                  this.setState({ currentMode: "datetime",showDatePicker: false });                      
                }
            }}
          />;
  }

  parseDate(value){
    if(value){
      this.setState({value:moment(value).format("YYYY-MM-DD hh:mm A ").toString()});
      return moment(value).format("YYYY-MM-DD hh:mm A ").toString();
    }
    else 
      return moment().format("YYYY-MM-DD hh:mm A").toString();
  }

  render() {
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder } = this.props;
    console.log(this.props)

    const datePicker = (
      <DatePicker
        defaultDate={this.state.value} 
        mode='datetime'
      />
    );
    return (
      <>
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        />

      <View style={styles.container}>
      <Text style={styles.label}>{label} <Text style={styles.placeholder}>{placeholder}</Text> </Text>  
     
          {
            (isIssuable && isCompleted) && 
            <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
              this.setState({dialogVisible:true});
            }}>
              <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
            </TouchableOpacity>
          }


        <PopPicker
          datePicker={datePicker}
          popupTransitionName="rmc-picker-popup-slide-fade"
          maskTransitionName="rmc-picker-popup-fade"
          title={label}
          date={this.state.value}
          mode='datetime'
          onChange={ date => {
            onChange(id, moment(date).format("YYYY-MM-DD HH:mm"))
            this.setState({value: date })
          }}
        >
          <TouchableHighlight
            style={styles.button}
            underlayColor={colors.secondary}
          >
            <Text style={styles.buttonText}>{this.state.value && moment(this.state.value).format("YYYY-MM-DD HH:mm" ) || 'open'}</Text>
          </TouchableHighlight>
        </PopPicker>
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  placeholder: {
    fontStyle: 'italic',
    color: '#002f6c',
    fontSize: 18,
  },
  time: {
    fontSize: 20,
    color: "#333",
    fontWeight: "200",
    marginBottom: 5,
    fontFamily: "Roboto",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",                 
  },
  modalView: {
    height: 370,      
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  androidPicker: { 
    flexDirection: "row", 
    justifyContent: "center", 
    alignItems: "center", 
    borderColor: "lightgray", 
    borderWidth: 1, 
    borderRadius: 5
  },
  androidDate:{ 
    fontSize: 20, 
    padding: 15, 
    fontWeight: "bold", 
    color: "gray"
  },
  datePicker:{
    padding: '1px 6px 1px 8px',
    borderRadius: '3px',
    
    borderWidth: 0,
    fontSize: '14px',
    width: '90%',
    borderColor: 'transparent'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #3b4a5c',
    fontSize: 16,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "300",
    fontFamily: "Roboto",
  },
});
