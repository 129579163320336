import React, { Component } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  Text,
  Alert,
  ActivityIndicator,
  TextInput,
  Platform,
  TouchableOpacity,
  FlatList,
  DatePickerIOS,
  View,
} from "react-native";
import { connect } from "react-redux";
import colors from "../constants/colors";
import moment from "moment-timezone";
import FormCard from "./widgets/formCard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import alerts from "./components/alert"
//import { Actions } from "react-native-router-flux";
import {
  setFilterSelected,
  setFormSelected,
  setTaskSelected,
  shouldReloadTasks,
  createNewTask,
  getTasks,
  setFormsFromAPI
} from "../actions/tasks";
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import { navigateForm } from "../actions/menu";
import { color } from "react-native-reanimated";
class NewTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_name: "",
      task_description: "",
      startDate: new Date(),
      expirationDate: new Date(),
      frequencies: [
        {
          text: "Day",
          isSelected: false,
        },
        {
          text: "Week",
          isSelected: false,
        },
        {
          text: "Month",
          isSelected: false,
        },
        {
          text: "Year",
          isSelected: false,
        },
      ],
      formData: this.props.formList,
      formFiltered: [],
      formSelected: {},
      isLoading: false,
    };
    this.registerNewTask=this.registerNewTask.bind(this);
  }

  changeButton = (number) => {
    let clone = this.state.frequencies;
    clone.map((item, index) => {
      if (index == number && !item.isSelected) {
        item.isSelected = true;
      } else if (item.isSelected && index != number) {
        item.isSelected = false;
      }
      return item;
    });
    this.setState({
      frequencies: clone,
    });
  };

  filterForms = (search) => {
    let clone = [];
    clone = this.state.formData.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ formFiltered: clone });
  };

  selectForm = (number) => {
    let clone = JSON.parse(JSON.stringify(this.state.formFiltered));
    
    clone.map((item, index) => {
      if (index == number && item.isSelected==false) {
        item.isSelected = true;
      } else if (item.isSelected==true && index != number) {
        item.isSelected = false;
      }
      return item;
    });
    
    this.setState({
      formFiltered: clone,
    });

  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props.setFormsFromAPI();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formList !== this.props.formList){
      this.setState(
        {
          formData: this.props.formList,
          isLoading: false,
        },
        () => {
          this.filterForms("");
        }
      );
    }
    if(prevProps.taskCreated !== this.props.taskCreated){
      this.props.selectForm(
        this.state.formSelected._id,
        this.state.formSelected.title,
        this.props.previousSubmissions,
        this.props.taskCreated._id
      );
    }
  }

  registerNewTask = () => {
    const { formSelected } = this.state;
    if (!formSelected.title) {
      alerts(
        "Please select a form",
        "You need to select a form in order to proceed.",
        [
          {
            text: "OK",
          },
        ]
      );
      return;
    }
    let start = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");
    let object = {
      name: formSelected.title,
      description: formSelected.title,
      forms_id: formSelected._id,
      start_date: start,
      expiration_date: moment(start).add(1, "day").format("YYYY-MM-DD HH:mm"),
      repeat_frequency: "",
    };
    this.props.createNewTask(object);
  };

  renderButtons = () => {
    let buttons = [];
    this.state.frequencies.forEach((item, index) => {
      buttons.push(
        <TouchableOpacity
          onPress={() => this.changeButton(index)}
          key={index + ""}
          style={[
            styles.button,
            {
              backgroundColor: item.isSelected
                ? colors.primary
                : colors.gray_darken_2,
            },
          ]}
        >
          <Text style={styles.buttonText}>{item.text}</Text>
        </TouchableOpacity>
      );
    });
    return buttons;
  };

  renderForms = () => {
    let formCards = [];
    this.state.formFiltered.forEach((item, index) => {
      formCards.push(
        <FormCard
          action={() => {
            this.selectForm(index);
            this.setState({
              formSelected: item,
            });
          }}
          key={index + ""}
          formTitle={item.title}
          formSections={item.sections.length}
          formDescription={item.description}
          formImageUri={item.image}
          isSelected={item.isSelected}
        />
      );
    });
    return formCards;
  };

  render() {

    if (Platform.OS === 'web') {
      return (
        <View style={{
          flexDirection: "row"
        }}>
          <View style={{ flex: 1 }}/>
          <View style={{ flex: 1, backgroundColor: "#fff", borderRadius: 20, marginTop: 10 }} >
            <View style={{ display: "flex", justifyContent: "center" }}>
              <Text style={{
                paddingLeft: 10,
                textAlign: "center",
                fontSize: 22,
                fontFamily: "Roboto-Medium",
                marginTop: 20,
                marginBottom: 10,

              }}>Add a new record</Text>
              <Text style={{
                color: colors.black,
                textAlign: "center",
                fontSize: 16,
                paddingLeft: 10,
                paddingBottom: 10,
                marginTop: 10,
                fontFamily: "Roboto",
              }}>
                Select the form you want to create your record from.
              </Text>
            </View>
            <View style={{
                height: 40,
                borderRadius: 20,
                flexDirection: "row",
                // backgroundColor: colors.gray_darken_2,
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10
            }}>
                <MaterialCommunityIcons
                  style={{paddingLeft: 40}}
                  name="magnify"
                  size={30}
                  color="#90A4AE"
                />
                <TextInput
                  style={
                    {
                      borderRadius: 20,
                      flex: 1,
                      maxWidth: 450,
                      height: 30,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      fontSize: 18,
                      color: "#90A4AE",
                    }
                  }
                  placeholder="Search for a form"
                  onChangeText={(searchString) => this.filterForms(searchString)}
                  underlineColorAndroid="transparent"
                />
              </View>
            <View style={{ backgroundColor: colors.gray_darken_2, margin: 10, borderRadius: 20 }}>
              
              {this.state.isLoading ? (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ActivityIndicator color={colors.primary} />
                  <Text>Loading forms, please wait.</Text>
                </View>
              ) : (
                <FlatList
                  data={this.state.formFiltered}
                  style={{ marginBottom: 20 }}
                  renderItem={({ item, index }) => (
                    <FormCard
                      action={() => {
                        this.selectForm(index);
                        this.setState({
                          formSelected: item,
                        }, () => { this.registerNewTask(); });
                      }}
                      key={index + ""}
                      formTitle={item.title}
                      formSections={item.sections.length}
                      formDescription={item.description}
                      formImageUri={item.image}
                      isSelected={item.isSelected}
                    />
                  )}
                  keyExtractor={(item) => item.id}
                />
              )}
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >

            </View>
          </View>
          <View style={{ flex: 1 }} />
        </View>
      );
    }else{
    
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Add a new record</Text>
        <Text style={styles.subtitle}>
          Select the form you want to create your record from.
        </Text>
        <View style={styles.searchSection}>
          <MaterialCommunityIcons
            style={styles.searchIcon}
            name="magnify"
            size={30}
            color="#90A4AE"
          />
          <TextInput
            style={styles.inputSearch}
            placeholder="Search for a form"
            onChangeText={(searchString) => this.filterForms(searchString)}
            underlineColorAndroid="transparent"
          />
        </View>
        {this.state.isLoading ? (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator color={colors.primary} />
            <Text>Loading forms, please wait.</Text>
          </View>
        ) : (
          <FlatList
            data={this.state.formFiltered}
            style={{ marginBottom: 80 }}
            renderItem={({ item, index }) => (
              <FormCard
                action={() => {
                  this.selectForm(index);
                  this.setState({
                    formSelected: item,
                  },  () => {this.registerNewTask();} );                  
                }}
                key={index + ""}
                formTitle={item.title}
                formSections={item.sections.length}
                formDescription={item.description}
                formImageUri={item.image}
                isSelected={item.isSelected}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        )}

        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          
        </View>
      </View>
    );
  }
}
}
const mapStateToProps = (state) => {
  return {
    token: state.user.get("authToken"),
    previousSubmissions: state.submissions.get("previousSubmissions"),
    formList: state.tasks.get("formList"),
    taskCreated: state.tasks.get("taskCreated"),
  };
};

export default connect(mapStateToProps, (dispatch, props) => {
  return {
    getTasks: () => {
      dispatch(getTasks());
    },
    setFilterSelected: () => {
      dispatch(setFilterSelected());
    },
    setFormSelected: () => {
      dispatch(setFormSelected());
    },
    setTaskSelected: () => {
      dispatch(setTaskSelected());
    },
    shouldReloadTasks: (value) => {
      dispatch(shouldReloadTasks(value));
    },
    setFormsFromAPI: () => {
      dispatch(setFormsFromAPI());
    },
    createNewTask: (task) => {     
      return new Promise((resolve, reject) => {
        dispatch(setIsSubmission(false));
        dispatch(setSubmissionSelected(null));
        dispatch(createNewTask(task));
        resolve();
      });    
    },
    selectForm: (id, name, previousSubmissions, task_id) => {
      if (!previousSubmissions[task_id]) {
          props.navigation.navigate('FormView', { formId: id, title: name, newReport:true, submission:false , task_id: task_id, log_group_id:null});    
        //dispatch(navigateForm(id, name, true, task_id));
        return;
      }

      alerts(
        "Open Report",
        "Would you like to start a new report, or reopen the previous report?",
        [
          {
            text: "New Report",
            onPress: () => {
              dispatch(navigateForm(id, name, true, task_id));
            },
            style: "cancel",
          },
          {
            text: "Reopen Previous Report",
            onPress: () => {
              dispatch(navigateForm(id, name, false, task_id));
            },
          },
          {
            text: "Cancel",
            onPress: () => {
              console.log("");
            },
            style: "cancel",
          },
          
        ]
      );
    },
  };
})(NewTask);

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    borderRadius: 100,
    height: 60,
    justifyContent: "center",
    marginBottom: 10,
    marginRight: 20,
    marginTop: 10,
    minWidth: 160,
    overflow: "hidden",
    padding: 10,
  },
  buttonCreate: {
    height: 70,
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowRadius: 6,
  },
  buttonCreateText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  container: {
    backgroundColor: "#F8F8F8",
    flex: 1,
    justifyContent: "space-between",
  },
  input: {
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 12,
    color: colors.gray_darken_2,
    fontFamily: "Roboto",
    fontSize: 18,
    height: 65,
    marginTop: 15,
    marginBottom: 15,
    paddingBottom: 0,
    paddingLeft: 20,
  },
  inputSearch: {
    flex: 1,
    height: 70,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 18,
    backgroundColor: "#fff",
    color: "#90A4AE",
  },
  labelInput: {
    color: colors.blue_gray,
    fontSize: 18,
    paddingLeft: 10,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: "Roboto",
  },
  title: {
    paddingLeft: 10,
    fontSize: 22,
    fontFamily: "Roboto-Medium",
    marginTop: 20,
    marginBottom: 10,
  },
  searchSection: {
    height: 70,
    flexDirection: "row",
    backgroundColor: "#fff",
    alignItems: "center",
    marginBottom: 10,
  },
  searchContainer: {
    flex: 1,
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchIcon: {
    paddingLeft: 20,
  },
  subtitle: {
    color: colors.black,
    fontSize: 16,
    paddingLeft: 10,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: "Roboto-Italic",
  },
  whiteSection: {
    backgroundColor: "#fff",
    marginTop: 20,
    padding: 10,
  },
});
