import ApiError from "./error";
import axios from "axios";
import mime from "mime";
export const api = "https://api.soft-hemp.com";
const apiAuth = "https://auth.soft-hemp.com";
import {Platform} from 'react-native';
const apiURL = api + "/api/v1";
import base64 from "base-64";

import { logout, authTokensUpdated } from "../actions/login";

let refreshingToken = false;

export function apiCall(dispatch, getState, method, path, body, opts) {
  return apiC(apiURL, dispatch, getState, method, path, body, true);
}

export function apiAuthCall(dispatch, getState, method, path, body, opts) {
  return apiC(apiAuth, dispatch, getState, method, path, body, false);
}

export function apiC(url, dispatch, getState, method, path, body, auth) {
  const fetchOpts = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    json: true,
    timeout: 15000, // 15 seconds
  };

  if (auth) {
    const token = getState().user.get("authToken");
    fetchOpts.headers.Authorization = "Bearer " + token;
  }

  if (body) {
    fetchOpts.body = JSON.stringify(body);
  }

  return fetch(url + path, Object.assign({}, {}, fetchOpts))
    .then((response) => {
      if (response && (response.status < 200 || response.status >= 300)) {
        return response.json().then(
          function (response) {
            if (typeof response.error === "string") {
              throw new ApiError(response);
            }
            throw new Error(`HTTP Error ${response.status}`);
          },
          function (error) {
            if (error.isApiError) {
              throw error;
            }
            throw new Error(`HTTP Error ${response.status}`);
          }
        );
      }
      
     /* if(body?.getAllResponse){
        console.log(JSON.stringify( response.json() ))
        return response;
      }
       */ 
      
      return response.json();
    })
    .catch((error) => {
      if (error.isApiError && error.reason === "token_expired") {
        if (!refreshingToken) {
          return refreshAuthToken(dispatch, getState).then(() =>
            apiC(...arguments)
          );
        }
      } else if (
        error.isApiError &&
        (error.reason === "token_not_found" ||
          error.reason === "authentication_failed")
      ) {
        dispatch(logout());
      } else {
        throw error;
      }
    });
}

export function refreshAuthToken(dispatch, getState) {
  refreshingToken = true;
  const token = getState().user.get("refreshToken");

  if (!token) {
    throw new Error("No refresh token");
  }

  return apiAuthCall(dispatch, getState, "POST", "/auth/refresh", { token })
    .then((data) => {
      //setHost(data.token);
      dispatch(authTokensUpdated(data));
    })
    .catch((error) => {
      // Log out if invalid token
      if (error.reason === "token_not_found") {
        dispatch(logout());
      } else {
        throw error;
      }
    })
    .finally(() => {
      refreshingToken = false;
    });
}

export function requestLogin(dispatch, getState, creds) {
  return apiAuthCall(dispatch, getState, "POST", "/auth/login", {
    email: creds.email,
    password: creds.password,
  }).then((data) => {
    //setHost(data.token);
    dispatch(authTokensUpdated(data));
    return data;
  });
}

export function requestMenu(dispatch, getState) {
  return apiCall(dispatch, getState, "GET", "/forms");
}

export function sendForm(dispatch, getState, dataSent, id, uuid) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/forms/submit/" + id,
    dataSent
  ).then((data) => {
    return data;
  });
}

export function syncErrorUpload(dispatch,getState,dataSent) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/submissions/syncerror",
    dataSent
  );
}

export function requestForm(dispatch, getState, id) {
  console.log('ID'+id)
  return apiCall(dispatch, getState, "GET", "/forms/retrieve/" + id).then((data) => {
    return data;
  });
}

export function uploadMediaB(dispatch, getState, data, uri) {
  
  const token = getState().user.get("authToken");
  let form = new FormData();
  let uriAndroid=uri;
  let extension = uri.substring(uri.lastIndexOf(".") + 1)?uri.substring(uri.lastIndexOf(".") + 1):"";
  
  if(Platform.OS==='ios'){
    form.append("image", {
      uri: uri,
      type: data,
      name: "image."+extension,
    });
  }
  if(Platform.OS==='android'){
    form.append("image", {
      uri: uriAndroid,
      type: mime.getType(uriAndroid),
      name: uriAndroid.split("/").pop(),
    });
  }
  
  if(Platform.OS==='web'){
    
    form.append("image", {
      uri: uri,
      type: "image/png",
      name: "image.png",
    });
  }

  
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploadExpo",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function uploadMedia(dispatch, getState, data, uri, name) {
  const token = getState().user.get("authToken");
  const extension = name.substring(name.lastIndexOf(".") + 1);
  let form = new FormData();
  const fileName = uri.split('/').pop();
  const fileType = mime.getType(uri);
  
  const byteString = atob(uri.split(',')[1]);
  
  const ab = new ArrayBuffer(byteString.length);
  const arr = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++)
    arr[i] = byteString.charCodeAt(i);

  const blob = new Blob([arr], {
    type: fileType,
  });

  const file = new File([blob], `${fileName}.${extension}`);

  form.append("document", file);
  
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploadmedia",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function uploadDocument(dispatch, getState, data, uri) {
  const token = getState().user.get("authToken");

  let form = new FormData();
  let uriAndroid=uri;
  let extension = uri.substring(uri.lastIndexOf(".") + 1);
  if(Platform.OS==='ios'){
    form.append("document", {
      uri: uri,
      type: mime.getType(uriAndroid),
      name: "document."+extension,
    });
  }else{
    form.append("document", {
      uri: uriAndroid,
      type: mime.getType(uriAndroid),
      name: uriAndroid.split("/").pop(),
    });
  }

  console.log('FORM =>'+JSON.stringify(form))
  return axios({
    method: "post",
    url: api + "/api/v1/media/uploaddocumentexpo",
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
}

export function setFileToFormTemplate(dispatch, getState, params){
  return apiCall(dispatch, getState, "POST", `/formbuilder/set_file_section`, params);
}

export function getApiPath() {
  return api;
}

export function getImage(url) {
  return RNFetchBlob.fetch("GET", api + url).then((response) => {
    return "data:image/png;base64," + response.base64();
  });
}

export function requestRegister(dispatch, getState, user_data) {
  return apiAuthCall(dispatch, getState, "POST", "/register", user_data).then(
    (data) => {
      return data;
    }
  );
}

export function getAllTasks(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/tasks", {}).then((data) => {
    return data;
  });
}
/*
export function getAllTaskByLog(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/loggroups/taskbylog", {}).then( data => {
    return data;
  });
}



export function getAllRecords(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/records", {}).then((data) => {
    return data;
  });
}
*/

export function getAllTaskByLog(dispatch, getState, {page}) {
  return apiCall(dispatch, getState, "POST", "/loggroups/taskbylog", {page, getAllResponse:true}).then( response => {
    return response;
  });
}

export function getAllRecords(dispatch, getState, {page}) {
  if(typeof page != "undefined"){
    return apiCall(dispatch, getState, "POST", "/records", {page, getAllResponse:true }).then(response => {
      console.log(JSON.stringify(response))
      return response;
    });
  }
  else{
    return apiCall(dispatch, getState, "POST", "/records", {}).then((data) => {
      return data;
    });
  }
}


export function recordInProgress(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/inprogress", record).then((data) => {
    return data;
  });
}

export function recordComplete(dispatch, getState,record) {  
  return apiCall(dispatch, getState, "POST", "/records/status/complete", record).then((data) => {
    return data;
  });
}

export function recordEditRequired(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/editrequired", record).then((data) => {
    return data;
  });
}

export function recordApproved(dispatch, getState,record) {
  return apiCall(dispatch, getState, "POST", "/records/status/approved", record).then((data) => {
    return data;
  });
}

export function archiveTask(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/tasks/save", task).then(
    (data) => {
      return data;
    }
  );
}
export function saveTasks(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/tasks/save", task).then(
    (data) => {
      return data;
    }
  );
}
export function getForms(dispatch, getState, task) {
  return apiCall(dispatch, getState, "POST", "/formbuilder/get", {}).then(
    (data) => {
      return data;
    }
  );
}
export function getLogGroups(dispatch, getState) {
  return apiCall(dispatch, getState, "POST", "/loggroups/get", {}).then(
    (data) => {
      return data;
    }
  );
}
export function getLogGroupsSub(dispatch, getState, id) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/submissions/logroupentries/" + id,
    {}
  ).then((data) => {
    return data;
  });
}
export function loadGroupDetail(dispatch, getState, id) {
  return apiCall(
    dispatch,
    getState,
    "POST",
    "/loggroups/getsingle/" + id,
    {}
  ).then((data) => {
    return data;
  });
}
export function signTask(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/tasks/signtask", data).then(
    (data) => {
      return data;
    }
  );
}

export function removeTask(dispatch, getState, data) {
  return apiCall(dispatch, getState, "POST", "/tasks/remove", data).then(
    (data) => {
      return data;
    }
  );
}
export function insertBase64(dispatch, getState, base64) {
  console.log('si llega al base64')
  return apiCall(dispatch, getState, "POST", "/media/insertbase64", {base64});
}
export function submitIssue(dispatch, getState, submissionId, issues) {
  return apiCall(dispatch, getState, "POST", `/submissions/issue/${submissionId}`, {issues});
}