import React, { Component } from "react";
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { submitForm, getForm, beginSubmit } from "../actions/menu";
import { sendSubmit } from "../actions/submissions";
import { setIsLogGroup, getGroupDetail, setLogGroupsSubAction} from "../actions/loggroups";
import moment from "moment";
import FormComponent from "./component";
import uuid from "uuid";
import LogDialog from "./dialogs/log";
import Section from "./components/section";
import RecordActions from "./widgets/recordActions";
import colors from "../constants/colors";
import alerts from "./components/alert"
import StorageService from "../services/storage";
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      checkboxkey: 0, // increment by 1 each time yesnoall is clicked
      components: [],
      choicelist: {},
      uuid: "",
      derived: false,
      autosave: false,
      tmpIssues:[],
    };
  }

  componentDidMount() {
    // Wait until UI transition finished
    this.props.getForm();
    /*this.props.navigation.addListener('beforeRemove', (e) => {
       alerts("Unsaved Changes will lost if you Go Back","",
       
          [
            { text: "Cancel", style: 'cancel', onPress: () => {console.log('cancel back')} },
            {
              text: 'Ok',
              style: 'destructive',
              // If the user confirmed, then we dispatch the action we blocked earlier
              // This will continue the action that had triggered the removal of the screen
              onPress: () => this.props.navigation.dispatch(e.data.action),
            },
          ]
       );
    })*/
    
  }

  setCompleteStatus(){
    const {submission} = this.state;
    submission.status = "complete";
    this.setState({submission: submission},()=>{
      this.submitForm();
    });
  }

  saveForm = () => {
    this.setState(
      {
        autosave: true,
      },
      () => {
        this.props.autoSave(
          this.state.components,
          this.state.choicelist,
          this.state.submission,
          this.props.newReport ? uuid.v1() : this.state.uuid,
          this.props.task_id,
          this.state.autosave
        );
      }
    );
  };

  getComponentById(id) {
    const { components } = this.state;
    var r = null;
    for (var i = 0; i < components.length; i++) {
      if (components[i]["id"] == id) return components[i];
    }
  }

  onComponentChange(id, value, subtype = false) {
    const { submission, components } = this.state;
    // let newSubmission = Object.assign({}, submission);
    let newSubmission =JSON.parse(JSON.stringify(submission));

    let checkboxkey = this.state.checkboxkey;
    var c = this.getComponentById(id);
    if(typeof c != "undefined"){ 
      if(subtype){
        if(typeof newSubmission[c.id] != "undefined"){
          newSubmission[c.id][subtype] = value;
      
        }else{
          newSubmission[c.id] = {};
          newSubmission[c.id][subtype] = value;
        }
      }
      else
        newSubmission[c.id] = value;
      // YesNoAll logic
      if (c.type === "yesnoall") {
        var section = components[id].section_id;
        components.forEach((c) => {
          if (c.type === "yesno" && c.section_id === section) {
            newSubmission[c.id] = value;
          }
        });
        // force yesno components to remount
        checkboxkey = checkboxkey + 1;
      }
    }else{
      if(subtype){
        newSubmission[id] = {};
        newSubmission[id][subtype] = value;
      }
      else
        newSubmission[id] = value;
    }

    this.setState({ submission: newSubmission, checkboxkey: checkboxkey });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    var submission = {};
    var components = [];
    var choicelist = {};
    var uuid = "";
    
    // Initialize right after the form is loaded from network/memory
    if (!newProps.isLoaded || !this.props.isLoading) {
      return;
    }

    // Load old submission
    if (!this.props.newReport && !this.props.isSubmission) {
      uuid = this.props.previousSubmissions[this.props.task_id]["uuid"];
      submission = this.props.previousSubmissions[this.props.task_id][
        "submission"
      ];
      components = this.props.previousSubmissions[this.props.task_id][
        "components"
      ];
      choicelist = this.props.previousSubmissions[this.props.task_id][
        "choicelist"
      ];
    }
    
    if (!this.props.newReport && this.props.isSubmission) {
      uuid = this.props.submissionSelected.uuid;
      submission = this.props.submissionSelected.submission;
      const tmpComponents = this.props.submissionSelected.components;
      choicelist = this.props.submissionSelected.choicelist;
      tmpComponents.forEach( (comp, indx) => {
        let nComponent = { ... comp }
        if(comp.type === "section"){
          newProps.components.forEach( nComp => {
            if( typeof comp.attachmentId !== 'undefined' && typeof nComp.attachmentId !== 'undefined' ){
              if(comp.attachmentId == nComp.attachmentId)
                nComponent['attachment'] = nComp.attachment;
            }
            else if(comp.id == nComp.id){
              nComponent['attachment'] = nComp.attachment;
              nComponent['attachmentId'] = nComp.attachmentId || null;
            }
            // this case is necesary for the first implementation, it may not be needed in the future
            else if(comp.label == nComp.label){
              nComponent['attachment'] = nComp.attachment;
              nComponent['attachmentId'] = nComp.attachmentId || null;
            }
          });
        }
        components.push(nComponent)
      })
    }

    if ("components" in newProps && "choicelist" in newProps) {
      const initialDateTime = moment().format("YYYY-MM-DD HH:mm");

      // New report
      if (this.props.newReport) {
        components = newProps.components;
        choicelist = newProps.choicelist;
        components.forEach((c) => {
          if (c.type === "datetime") submission[c.id] = initialDateTime;
          else if (
            c.type === "inputlabel" ||
            c.type === "input" ||
            c.type === "textarea"||
            c.type === "lotecode"||
            c.type === "customlotcode"
          )
            submission[c.id] = "";
          else if (c.type === "dropdown")
            submission[c.id] = choicelist[c.object_type_id][0]["id"];
          else if (c.type === "picture" || c.type === "signature")
            submission[c.id] = 0;
          else if (c.type == "yesno" || c.type == "yesnoall")
            submission[c.id] = 0;
            else if (c.type == "document")
            submission[c.id] = "";
        });
      }
    }
    this.setState({
      submission,
      components,
      choicelist,
      uuid,
    });
  }

  // Do not render when this.state.submissions is modified
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isErrorSave !== nextProps.isErrorSave ||
      this.props.isErrorRetrieve !== nextProps.isErrorRetrieve ||
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.isSaving !== nextProps.isSaving ||
      this.state.checkboxkey !== nextState.checkboxkey
    );
  }

  submitFormSave(){
    this.props.startSubmit(
      this.state.components,
      this.state.choicelist,
      this.state.submission,
      this.props.newReport ? uuid.v1() : this.state.uuid,
      this.props.task_id ? this.props.task_id : this.props.submissionSelected.task_id,
      this.props.isLogGroup ? this.props.logGroupSelected.id : '',      
    );
    this.props.logGroupSelected.id && this.props.isLogGroup?this.navigateToGroup():this.props.navigation.navigate('MainView');
    
  }

  submitForm() {
    let cont = 0;
    let labels = [];
    const result = this.state.components.filter(f => f.mandatory === true);

    for (let i = 0; i < result.length; i++) {
      let index = Object.keys(this.state.submission).indexOf(result[i].id);
      let valueMandatory = Object.values(this.state.submission)[index];
      console.log("--------------------------------")
      console.log(result[i].label)
      console.log(valueMandatory)
      console.log("--------------------------------")

      if(valueMandatory != undefined){
      if (valueMandatory.length > 0 || valueMandatory != 0 ) {

        if (result[i].type === 'inventory') {
          if (valueMandatory.lotcode && valueMandatory.productname && valueMandatory.quantity) {
            if (valueMandatory.lotcode.length > 0 && valueMandatory.productname.length > 0 && valueMandatory.quantity.length > 0) {
              console.log(valueMandatory)
              console.log("success")
              cont++;
            } else {
              labels.push(result[i].label);
              console.log("error")
            }
          } else {
            labels.push(result[i].label);
            console.log("error")
          }

        } else {
          console.log("success")
          cont++;
        }
      }else{
        labels.push(result[i].label);
        console.log("error")
      }
    }else{
      labels.push(result[i].label);
    }
    }

    if (cont === result.length) {
      console.log("SAVED!!")
      this.props.startSubmit(
        this.state.components,
        this.state.choicelist,
        this.state.submission,
        this.props.newReport ? uuid.v1() : this.state.uuid,
        this.props.task_id ? this.props.task_id : this.props.submissionSelected.task_id,
        this.props.isLogGroup ? this.props.logGroupSelected.id : '',
      );
      this.props.logGroupSelected.id && this.props.isLogGroup?this.navigateToGroup():this.props.navigation.navigate('MainView');
      
    } else {
      alerts(
        "Please check your form",
        "You need fill all the required fields "+ '('+labels.toString().replaceAll(",",", ")+')',
        [
          { text: "OK", onPress: () => console.log("--------------------------------") },
        ]
      );
    }
    
  }


  navigateToGroup(){
      this.props.setLogGroupsSubAction(),
      this.props.navigation.navigate('GroupView');
  }

  renderIssue(issues,component){
    let issueComment = "";
    let found = false;
    issues.forEach((issue)=>{        
        if(issue.id == component.id){
          issueComment = issue.comment;
          found = true;
        } 
    });    
    return found ? (
      <TouchableOpacity style={styles.issueContainer}>
        <Text style={{alignSelf: "center"}}>{issueComment}</Text>
      </TouchableOpacity>
    ) : null;

  }

  showSave(){

    const { submissionSelected } = this.props;       
    const status = submissionSelected ? submissionSelected.status : "new";
    
    if(status != 'archived' && status != 'approved' && status != 'complete')
      return (
        <View style={styles.submitContainer}>
          <TouchableOpacity
            style={styles.button}            
            onPress={() => {
              this.submitFormSave();
            }}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </View>
      );
    else if(status != 'complete')
      return (
        <TouchableOpacity style={styles.readOnlyContainer}>
          <Text style={{alignSelf: "center"}}>This record is read only</Text>
        </TouchableOpacity>
      );
            
    
  }

  saveIssue(issue, issues, submissionId){
    
    if(issues)
      issues.push(issue);
    else{
      issues = [issue];
    }
    this.setState({tmpIssues: issues});
    this.props.sendSubmit(submissionId, issues, this.forceUpdate.bind(this) );
  }


  render() {
    const { submission, components, choicelist, checkboxkey } = this.state;
    const { submissionSelected } = this.props;       
    let issues = submissionSelected ? submissionSelected.issues : null;
    if(this.state.tmpIssues.length > 0)
      issues = this.state.tmpIssues;
    const submissionId = submissionSelected ? submissionSelected._id : null ;
    console.log(components)
    if (Platform.OS === 'web') {
      return (
      <View style={{flexDirection: "row",}}>
        <View style={{ flex: 2,}}/>
        <View style={{ flex: 2, maxWidth: 800,  padding: 20, margin: 30, borderRadius: 20, backgroundColor: "#fff"}} >
    
        {this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (
          
          <ScrollView
            contentContainerStyle={styles.innerView}
          >
            {components.filter(section => section.type == 'section').map((section,id) => (
                <Section 
                  showSeparator={id === 0} 
                  isLast={id == components.filter(section => section.type == 'section').length -1} 
                  label={section.label}
                  onChange={(id, value, subtype) => this.onComponentChange(id,value, subtype)} 
                  id={section.id}
                  media={section.attachment}
                  mediaId={ typeof section.attachmentId !== 'undefined' ? section.attachmentId : null }
                  formId={this.props.formId}
                >                   
                    {components.filter(component => component.section_id == section.section_id && component.type != 'section' ).map((component) => {
                      let new_label;
                      console.log(component.placeholder);
                      switch(component.type){
                        case "customlotcode":
                          new_label = `${component.label} - ${component.lotcodetypename}`;
                          break;
                        case "inventory":
                          new_label = `${component.label} - ${component.inventorytypename} Code`;
                          if(typeof submission[component.id] == "undefined")
                          submission[component.id] = {lotcode: "", productname: "", quantity: ""};
                          break;
                        default:
                          new_label = `${component.label}`;
                      }
                      
                        return <View>
                                <FormComponent
                                  id={component.id}
                                  type={component.type}
                                  key={component.id}
                                  label={component.mandatory ? new_label+" *": new_label}     
                                  placeholder={component.placeholder ? component.placeholder : ""}                      
                                  initial={submission[component.id]}
                                  onChange={(id, value, subtype) => {
                                    this.onComponentChange(id, value, subtype)

                                  }}
                                  options={choicelist[component.object_type_id]}
                                  checkboxkey={checkboxkey}
                                  status={submissionSelected?submissionSelected.status:false}
                                  customlot={false}
                                  isIssuable={submissionId != null}
                                  onSubmitIssue={ (issue) => this.saveIssue(issue, issues, submissionId) }

                                />
                                {issues ? this.renderIssue(issues,component):null}
                                
                              </View>
                      
                    })}
                </Section>
             ))}

            <RecordActions setCompleteStatus={()=>{this.setCompleteStatus()}} submitForm={()=>this.submitForm()} submitFormSave={()=>this.submitFormSave()} record={this.props.submissionSelected} />         
            
            {this.showSave()}
            
          </ScrollView>          
        ) : this.props.isLoading ? (
          <View style={styles.fixedContainer}>
            <ActivityIndicator></ActivityIndicator>
            <Text style={styles.loadingText}>Loading...</Text>
          </View>
        ) : this.props.isSaving && !this.state.autosave ? (
          <View style={styles.fixedContainer}>
            <ActivityIndicator></ActivityIndicator>
            <Text style={styles.loadingText}>Saving...</Text>
          </View>
        ) : (
          <View style={styles.fixedContainer}>
            <View style={styles.fixedContainerHT}>
              <MaterialCommunityIcons
                name="alert-circle"
                size={32}
                color="#d00"
                style={styles.icon}
              />
              <Text style={styles.errorText}>{this.props.error}</Text>
            </View>
            <View style={styles.fixedContainerHB}>
              <TouchableOpacity
                onPress={() => {
                  this.props.retry(
                    this.props.isErrorSave,
                    this.props.components,
                    this.props.choicelist,
                    this.state.submission,
                    this.props.newReport ? uuid.v1() : this.state.uuid,
                    this.props.task_id
                  );
                }}
              >
                <Text>Retry</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        <LogDialog />
        </View>
        <View style={{ flex: 2}}/>
      </View>
      );
    }else{
      return (
        <KeyboardAvoidingView
          style={styles.mainContainer}
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          keyboardVerticalOffset={100}
        >
          {this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (
            
            <ScrollView
              style={styles.menuContainer}
              contentContainerStyle={styles.innerView}
            >
              {components.filter(section => section.type == 'section').map((section,id) => (
                  <Section 
                    showSeparator={id === 0} 
                    isLast={id == components.filter(section => section.type == 'section').length -1} 
                    label={section.label}
                    onChange={(id, value, subtype) => this.onComponentChange(id,value, subtype)} 
                    id={section.id}
                    media={submission[section.id]}
                  >                   
                      {components.filter(component => component.section_id == section.section_id && component.type != 'section' ).map((component) => {
                        let new_label;
                        switch(component.type){
                          case "customlotcode":
                            new_label = `${component.label} - ${component.lotcodetypename}`;
                            break;
                          case "inventory":
                            new_label = `${component.label} - ${component.inventorytypename} Code`;
                            if(typeof submission[component.id] == "undefined")
                              submission[component.id] = {};
                            break;
                          default:
                            new_label = `${component.label}`;
                        }
                        
                          return <View>
                                  <FormComponent
                                    id={component.id}
                                    type={component.type}
                                    key={component.id}
                                    label={component.mandatory ? new_label+" *": new_label} 
                                    placeholder={component.placeholder ? component.placeholder : "" }                        
                                    initial={submission[component.id]}
                                    onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                                    options={choicelist[component.object_type_id]}
                                    checkboxkey={checkboxkey}
                                    status={submissionSelected?submissionSelected.status:false}
                                    customlot={false}
                                    isIssuable={submissionId != null}
                                    onSubmitIssue={ (issue) => this.saveIssue(issue, issues, submissionId) }
                                  />
                                  {issues ? this.renderIssue(issues,component):null}
                                   
                                </View>
                        
                      })}
                  </Section>
               ))}
  
              <RecordActions setCompleteStatus={()=>{this.setCompleteStatus()}} submitForm={()=>this.submitForm()} submitFormSave={()=>this.submitFormSave()} record={this.props.submissionSelected} />         
              
              {this.showSave()}
              
            </ScrollView>          
          ) : this.props.isLoading ? (
            <View style={styles.fixedContainer}>
              <ActivityIndicator></ActivityIndicator>
              <Text style={styles.loadingText}>Loading...</Text>
            </View>
          ) : this.props.isSaving && !this.state.autosave ? (
            <View style={styles.fixedContainer}>
              <ActivityIndicator></ActivityIndicator>
              <Text style={styles.loadingText}>Saving...</Text>
            </View>
          ) : (
            <View style={styles.fixedContainer}>
              <View style={styles.fixedContainerHT}>
                <MaterialCommunityIcons
                  name="alert-circle"
                  size={32}
                  color="#d00"
                  style={styles.icon}
                />
                <Text style={styles.errorText}>{this.props.error}</Text>
              </View>
              <View style={styles.fixedContainerHB}>
                <TouchableOpacity
                  onPress={() => {
                    this.props.retry(
                      this.props.isErrorSave,
                      this.props.components,
                      this.props.choicelist,
                      this.state.submission,
                      this.props.newReport ? uuid.v1() : this.state.uuid,
                      this.props.task_id
                    );
                  }}
                >
                  <Text>Retry</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
  
          <LogDialog />
        </KeyboardAvoidingView>
      );
    }
    
  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      isErrorSave: state.forms.getIn(["form", "status"]) === "errorSaving",
      isErrorRetrieve:
        state.forms.getIn(["form", "status"]) === "errorRetrieving",
      formId: state.forms.getIn(['form', 'id']),
      isLoaded: state.forms.getIn(["form", "status"]) === "loaded",
      isSaving: state.forms.getIn(["form", "status"]) === "saving",
      error: state.forms.getIn(["form", "errorMessage"]),
      components: state.forms.getIn(["form", "components"]),
      choicelist: state.forms.getIn(["form", "choicelist"]),
      previousSubmissions: state.submissions.get("previousSubmissions"),
      /*syncState: state.sync.get("state"),*/
      isLogGroup: state.loggroups.get("isLogGroup"),
      logGroupSelected: state.loggroups.get("logGroupSelected"),
      submissionSelected: state.submissions.get("submissionSelected"),
      isSubmission: state.submissions.get("isSubmission"),
    };
  },
  (dispatch, props) => {
    console.log(props.formId)
    return {
      sendSubmit : (submissionId, submissions, callback) => dispatch(sendSubmit(submissionId, submissions, callback)),
      getForm: (id) => {
        dispatch(getForm(props.formId));
      }, 
      setLogGroupsSubAction: () => {
        dispatch(setLogGroupsSubAction());
      },
      retry: (
        isErrorSave,
        components,
        choicelist,
        submission,
        uuid,
        task_id
      ) => {
        if (isErrorSave) {
          dispatch(
            beginSubmit(
              props.formId,
              props.title,
              components,
              choicelist,
              submission,
              uuid,
              task_id,
              false
            )
          );
        } else {
          dispatch(getForm(props.formId));
        }
      },
      startSubmit: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        logroup,        
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            false,
            logroup,            
          )
        );
      },
      autoSave: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        autosave
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            autosave
          ) 
        );
      },
    };
  }
)(Form);

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  issueContainer: {
    flexGrow:1,
    backgroundColor: "pink",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10, 
  },
  readOnlyContainer: {
    flexGrow:1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10, 
  },
  container: {
    flex: 1,
    backgroundColor: "#B3B7BC",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoid: {
    flex: 1,    
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  fixedContainer: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
  },
  fixedContainerHB: {
    marginTop: 20,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  innerView: {
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,    
  },
  menuContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,    
  },
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.gray_darken_2,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  buttonText: {
    color: colors.primary,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  iconInProgress: {    
    marginHorizontal: 10,
    fontSize: 30,         
    alignSelf: "center", 
    color: 'lightgray',
  },
  iconEditRequired: {    
    marginHorizontal: 10,
    fontSize: 30,         
    alignSelf: "center", 
    color: 'lightgray',
  },
  iconReadyForReview: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgray',
    },
    iconReadyForApproval: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightblue',
    },
    iconApproved: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'lightgreen',
    },
    iconRejected: {    
      marginHorizontal: 10,
      fontSize: 30,         
      alignSelf: "center", 
      color: 'pink',
    },
});