import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class InputWithLabel extends Component {
  state = {
    value: this.props.initial,
    dialogVisible: false,
  };

  render() {
    const { value } = this.state;
    const { id, onChange, label, isIssuable, onSubmitIssue, isCompleted } = this.props;
    return (
      <>
        <NewIssue 
          visible={this.state.dialogVisible} 
          id={id} 
          label={label} 
          onSubmitIssue={onSubmitIssue} 
          handleClose={()=>this.setState({dialogVisible:false})} 
        />

        <View style={styles.container}>
          <Text style={styles.label}>{label}</Text>
          {
            (isIssuable && isCompleted) && 
            <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
              this.setState({dialogVisible:true});
            }}>
              <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
            </TouchableOpacity>
          }
          <TextInput
            value={value}
            style={styles.input}
            // placeholder={this.props.placeholder}
            onChangeText={(val) => {
              this.setState({ value: val });
              onChange(id, val);
            }}
          />
        </View>
      </>

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    height: 60,
    flex: 1,
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
