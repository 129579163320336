import React, { Component } from "react";
import {
  StyleSheet,
  Dimensions,
  View,
  Text,
  TouchableHighlight,
  Image,
  ProgressViewIOS,
  Alert,
  Platform,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
// import ImagePicker from 'react-native-image-picker';
import * as ImagePicker from "expo-image-picker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Lightbox from "react-native-lightbox";
import { getApiPath, uploadMediaB, uploadMediaWeb } from "../../api";
import * as Permissions from "expo-permissions";
import colors from "../../constants/colors";
import alerts from "./alert";
import NewIssue from "../dialogs/newIssue";
export default class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      pictureText: this.props.placeholder,
      imagePath:
        typeof this.props.initial === "object"
          ? this.props.initial
          : "selected",
      imageState: this.props.initial ? "selected":"select",
      ignoreInitial: false,
      dialogVisible: false,
    };
  }

  pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.6,
      base64:false 
    });

    if (!result.cancelled) {
      this.uploadImage(result.uri, result.type);
      // setImage(result.uri);
    }
  };

  takePhoto = async () => {
    const result = await ImagePicker.launchCameraAsync({
      allowEditing: false,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],
      quality: 0.6,
    });

    if (!result.cancelled) {
      this.uploadImage(result.uri, result.type);
    }

  };

  showAlert = () =>{
    Alert.alert(
      "Where do you want to pick your image?",
      "",
      [
        {
          text: "Take a picture",
          onPress: () => {
            this.pickCameraPermission();
          },
        },
        {
          text: "Choose from gallery",
          onPress: () => {
            this.pickGalleryPermission();
          },
        },
        {
          text: "Cancel",
          onPress: () => {},
        },
      ],
      { cancelable: false }
    );
  }

  pickCameraPermission = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);

    if (status == "granted") {
      this.takePhoto();
    } else if (status == "denied") {
      Alert.alert(
        "We need your permission",
        "Please grant access to the camera in order to continue",
        [
          {
            text: "Cancelar ",
            onPress: () => {},
          },
          {
            text: "Aceptar",
            onPress: async () => {
              const { status } = await Permissions.askAsync(Permissions.CAMERA);
              if (status == "granted") {
                this.takePhoto();
              }
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      Alert.alert(
        "We need your permission",
        "Please grant access to the camera in order to continue",
        [
          {
            text: "Cancelar ",
            onPress: () => {},
          },
          {
            text: "Aceptar",
            onPress: async () => {
              const { status } = await Permissions.askAsync(Permissions.CAMERA);
              if (status == "granted") {
                this.takePhoto();
              }
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  pickGalleryPermission = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status == "granted") {
      this.pickImage();
    } else if (status == "denied") {
      Alert.alert(
        "We need your permission",
        "Please grant access to the gallery so you can continue",
        [
          {
            text: "Cancelar ",
            onPress: () => {},
          },
          {
            text: "Aceptar",
            onPress: async () => {
              const { status } = await Permissions.askAsync(
                Permissions.CAMERA_ROLL
              );
              if (status == "granted") {
                this.pickImage();
              }
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  updateUploadProgress(progress) {
    this.setState({ uploadProgress: progress });
  }

  uploadImage(uri, data) {
    if(Platform.OS=='ios'){
      this.setState({ imagePath: uri.replace("file://", "") });
    }else{
      this.setState({ imagePath: uri });
    }
    if(Platform.OS=='web'){
      //this.setState({ imagePath: uri.replace("file://", "") });
      data='image';
    }
    
    this.updateImage(data, uri);
    return;

    this.setState({
      imageState: "uploading",
      pictureText: "Uploading...",
    });

    var r = this.updateUploadProgress.bind(this);
    uploadMediaB(
      data,
      uri,
      function (written, total) {
        r(written / total);
      },
      (res) => {
        this.setState({ imagePath: uri.replace("file://", "") });
        var data = JSON.parse(res.data);
        this.updateImage(data.id);
      },
      (err) => {
        Alert.alert("Error", "Error uploading file", [{ text: "OK" }]);
        this.setState({
          imageId: 0,
          uploadProgress: 0,
          imageState: "select", // select / uploading / selected
          pictureText: "No picture added",
        });
      }
    );
  }

  updateImage(data, uri) {
    const { id } = this.props;
    if(Platform.OS=='web'){
      if(uri.substr(5,6) != "image/"){  
        alerts(
          "Please select only images.",
          "",
          [
            {
              text: "Ok",
              onPress: () => {
              },
              
            },
          ]
        );
        return;
      }
      else {
        this.props.onChange(id, uri);
      }
    }else{
      this.props.onChange(id, { data, uri });
    }
    

    this.setState({
      //imageId: id,
      imageState: "selected",
    });
  }

  progressBarStyle(progress) {
    return {
      position: "absolute",
      top: 0,
      left: 0,
      width: 370 * progress,
      bottom: 0,
      backgroundColor: "#6663",
    };
  }

  removePicture() {
    if(Platform.OS!='web'){
    Alert.alert(
      "Remove Picture",
      "Are you sure you would like to remove this picture?",
      [
        { text: "Cancel", onPress: () => {}, style: "cancel" },
        {
          text: "OK",
          onPress: () => {
            this.setState({
              imageId: 0,
              uploadProgress: 0,
              imageState: "select", // select / uploading / selected
              pictureText: "No picture added",
              imagePath: null,
              ignoreInitial: true,
            });

            this.props.onChange(this.props.id, 0);
          },
        },
      ]
    );
    }else{
      alerts(
        "Remove Picture",
        "Are you sure you would like to remove this picture?",
        [
          { text: "Cancel", onPress: () => {}, style: "cancel" },
          {
            text: "OK",
            onPress: () => {
              this.setState({
                imageId: 0,
                uploadProgress: 0,
                imageState: "select", // select / uploading / selected
                pictureText: "No picture added",
                imagePath: null,
                ignoreInitial: true,
              });
  
              this.props.onChange(this.props.id, 0);
            },
          },
        ]
      );
    }
  }

  render() {    
    const {ignoreInitial} = this.state; 
    const { initial,label, isIssuable, onSubmitIssue, id, isCompleted } = this.props;
    let initialImage=String(initial);
    if(initialImage==0)
        initialImage=false;

    if(initialImage!=false){ 
        console.log(initialImage)
        if(!initialImage.includes('base64'))
            initialImage=getApiPath() + initial;
    }

    return (
      <>
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        />

      <View style={styles.container}>
        <Text style={styles.label2}>{label}</Text>
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            this.setState({dialogVisible:true});
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        {this.state.imageState == "selected" ? (
          <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <View style={styles.wrapperUploaded}>
             {Platform.OS === 'web' ? (
               <Image
               source={initialImage && !ignoreInitial ? {uri: initialImage } :  { uri: this.state.imagePath } }
                style={{
                  width: 500, height: 500
                }}
             />
             ): (
              <Image
              source={initialImage && !ignoreInitial ? {uri: initialImage } :  { uri: this.state.imagePath } }
              style={{                  
                aspectRatio: 1,
                width: Dimensions.get("window").width - 80,
                height: 320,
              }}
            />
             )}
            </View>
            <TouchableHighlight
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                backgroundColor: "white",
                height: 40,
                width: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
              }}
              onPress={() => {
                this.removePicture();
              }}
            >
              <MaterialCommunityIcons
                name="delete"
                size={28}
                color="red"
                style={styles.icon}
              />
            </TouchableHighlight>
          </View>
        ) : (
          <View style={styles.wrapperInitial}>
            {/* <View style={this.progressBarStyle(this.state.uploadProgress)}/> */}
            <TouchableHighlight
              style={styles.button}
              underlayColor={colors.secondary}
              onPress={Platform.OS=='web'?this.pickImage:this.showAlert}
            >
              <Text style={styles.buttonText}>Add picture</Text>
            </TouchableHighlight>
            <Text style={styles.label}>{this.state.pictureText}</Text>
          </View>
        )}
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingTop: 5,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  wrapperInitial: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  wrapperUploaded: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "300",
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 16,
    color: "#9B9B9B",
    fontWeight: "300",
    marginLeft: 10,
    fontFamily: "Roboto",
  },
  image: {
    maxHeight: 80,
  },
  label2: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
