import React, { Component } from "react";
import {
  Image,
  View,
  StyleSheet,
  Text,
  TextInput,  
  Switch,
  TouchableOpacity,
  Alert,  
  ScrollView,
  Platform
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "../constants/colors";
import {
  getMenu,
  getMenuImages,
  navigateMenu,
  navigateForm,
} from "../actions/menu";
import { startingSync, sync } from "../actions/submissions";
import { connect } from "react-redux";
import { login, logout, authTokensUpdated } from "../actions/login";
import AppJson from "../../app.json";
import LottieView from 'lottie-react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-community/async-storage';
import Animations from './animations';
import CheckBox from '../components/widgets/checkbox';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      viewPass:false,
    };
  }

  // beginSync() {
  //   const { syncState } = this.props;

  //   if (syncState == 'startingSync' || syncState == 'syncInProgress')
  //     return;

  //   this.props.dispatch(startingSync());
  //   setTimeout(() => {
  //     this.props.sync();
  //   }, 5 * 1000); // 5 sec
  // }

  componentDidMount() {
    this.props.dispatch(logout());
    this.getRememberedUser();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const refresh = urlParams.get('refresh');
    if(token && refresh){
      this.props.setToken({
        token: urlParams.get('token'),
        refresh: urlParams.get('refresh'),
      });
      urlParams.delete('token');
      urlParams.delete('refresh');
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.authToken &&
      newProps.authToken.length > 0 &&
      newProps.currentView == "login"
    ) {
      this.props.navigateMenu();
    }
  }

  rememberUser = async () => {
    try {
      await AsyncStorage.setItem(
        "@iqops-email",
        this.state.email ? this.state.email : ""
      );
      await AsyncStorage.setItem(
        "@iqops-passw",
        this.state.password ? this.state.password : ""
      );
    } catch (error) {
      // Error saving data
    }
  };

  getRememberedUser = async () => {
    try {
      const username = await AsyncStorage.getItem("@iqops-email");
      const passw = await AsyncStorage.getItem("@iqops-passw");
      if (username !== null) {
        // We have username!!
        this.setState({
          email: username,
          password: passw,
          rememberMe: username ? true : false,
        });
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  forgetUser = async () => {
    try {
      await AsyncStorage.removeItem("@iqops-email");
      await AsyncStorage.removeItem("@iqops-passw");
    } catch (error) {
      // Error removing
    }
  };

  toggleRememberMe = (value) => {
    this.setState({ rememberMe: value });
    if (value === true) {
      //user wants to be remembered.
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  };
  
  render() {
    const { email, password, rememberMe } = this.state;
    //const { syncState, loginLoading } = this.props;
    const {syncState, loginLoading, userToken } = this.props;
    const keyboardHeight = this.props.keyboard;
    
    if(userToken){
      this.props.navigation.navigate('MainView');
    }
    
    return (
      <ScrollView contentContainerStyle={styles.container}>

        <View
          style={{
            flex: 7,
            display: 'flex', alignItems: 'center'
          }}
        >
          <View style={styles.fixedContainer}>
            <View style={styles.fixedContainerHT}>
              <MaterialCommunityIcons
                name="account-circle"
                size={32}
                color="#d00"
                style={styles.icon}
              />
              <TextInput
                value={email}
                style={styles.input}
                placeholder="Email"
                autoCapitalize="none"
                onChangeText={(val) => {
                  this.setState({ email: val });
                }}
              />
            </View>
            <View style={styles.fixedContainerHT}>
              <MaterialCommunityIcons
                name="lock"
                size={32}
                color="#d00"
                style={styles.icon}
              />
              <TextInput
                secureTextEntry={!this.state.viewPass ? true: false}
                value={password}
                style={[styles.inputPass]}
                placeholder={"Password"}
                onChangeText={(val) => {
                  this.setState({ password: val });
                }}
              />
              <MaterialCommunityIcons
                name="eye-circle"
                size={32}
                color={colors.gray_darken_2}
                style={styles.iconEye, {position: 'absolute', 
                right: 10,}}
                onPress={() => {
                  this.setState({ viewPass: !this.state.viewPass })          
                }}
              />
            </View>
            <View style={styles.fixedContainerHB}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  if(!loginLoading){                    
                    this.toggleRememberMe(this.state.rememberMe);
                    
                    if(this.state.email==''){
                      alert('Please Add a Valid Email')
                    }
                    else if(this.state.password==''){
                      alert('Please Add a Valid Password')
                    }else{
                      this.props.login(this.state.email, this.state.password);
                    }
                    
                  }                  
                }}
              >
                <View style={{display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{ color: "white", fontSize: 18}}>Login</Text>
                {loginLoading ? <Animations animationfile='loading-dots'/> : null}
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.rememberView}>
              <CheckBox initial={rememberMe} onPress={(val) => { this.toggleRememberMe(val) }} label={'Remember me'} />
            </View>
            <View>
            <Text style={{textAlign: 'center', fontStyle: 'italic', color: '#002f6c'}}>You dont have an account?</Text>
            <TouchableOpacity
            style={styles.signUpButton}
            onPress={()=> this.props.navigation.navigate('Register')}            
          >

            <Text style={{textAlign: 'center',  fontWeight: "bold", color: colors.orange}}>Sign up</Text>
          </TouchableOpacity>
            </View>
          </View>
          <Text style={styles.versionText}>Version {AppJson.expo.version}</Text>
        </View>

        <View style={styles.signUpContainer}>
          {/* <TouchableOpacity
            style={styles.signUpButton}
            onPress={()=> this.props.navigation.navigate('Register')}            
          >
            <Text style={styles.signUp}>Sign Up</Text>
          </TouchableOpacity> */}
        </View>
      </ScrollView>
    );
  }
}

export default connect(
  (state) => {
    return {  
      loginLoading: state.user.get("loginLoading"), 
      userToken: state.user.get('authToken'),   
    };
  },
  (dispatch, props) => {
    return {
      selectForm: (id, name, previousSubmissions) => {
        if (!previousSubmissions[id]) {
          dispatch(navigateForm(id, name, true));
          return;
        }

        alert(
          "Open Report",
          "Would you like to start a new report, or reopen the previous report?",
          [
            {
              text: "New Report",
              onPress: () => {
                dispatch(navigateForm(id, name, true));
              },
              style: "cancel",
            },
            {
              text: "Reopen Previous Report",
              onPress: () => {
                dispatch(navigateForm(id, name, false));
              },
            },
          ]
        );
      },
      navigateMenu: () => {
        dispatch(navigateMenu());
      },
      login: (email, password) => {
        dispatch(login(email, password));
      },
      sync: () => {
        dispatch(sync());
      },
      getMenuImages: () => dispatch(getMenuImages()),
      setToken : (tokens) => dispatch(authTokensUpdated(tokens)),
      dispatch,
    };
  }
)(Login);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: "#F3F3F3",
  },
  topBar: {
    flex: 1,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 2,
    shadowOpacity: 0.2,
    shadowColor: "black",
    paddingVertical: 0,
  },
  bottomBar: {
    flexDirection: "column",    
    alignContent: "flex-end",
  },
  title: {
    paddingTop: 15,
    color: "#fff",
    fontSize: 40,
    textAlign: "center",
    backgroundColor: "transparent",
    fontFamily: "Roboto-Bold",
  },
  loadingAnimation: { 
    height: 60,
    width: 60,
    position: "absolute",
    right: 0,     
  },
  shipName: {
    paddingTop: 10,
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
    backgroundColor: "transparent",
    fontWeight: "400",
    fontFamily: "Roboto",
  },
  wordTwo: {
    fontFamily: "Roboto",
  },
  fixedContainer: {
    width: 300,
    backgroundColor: "white",
    borderRadius: 6,
    elevation: 3,
    flexDirection: "column",
    padding: 20,
    marginHorizontal: 20,
    marginTop: 50,
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: 3,
    shadowOffset: {
      width: 1,
      height: 1,
    },
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  fixedContainerHB: {
    marginTop: 5,
  },
  innerView: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 75,
    paddingRight: 75,
  },
  logBox: {
    marginTop: 10,
    marginBottom: 10,
    height: 230,
    width: 400,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 5,
    shadowOpacity: 0.2,
    shadowColor: "black",
  },
  logImage: {
    height: 230,
    width: 400,
  },
  logTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    color: "#fff",
    fontSize: 24,
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
    fontFamily: "Roboto",
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  btn: {
    margin: 10,
    backgroundColor: "#3B5998",
    padding: 10,
  },
  text: {
    fontSize: 22,
  },
  dialogTableL: {
    width: 80,
    fontWeight: "600",
  },
  input: {
    flex: 1,
    height: 58,
    paddingLeft: 55,
    marginLeft: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#444",
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 6,
  },
  inputPass: {
    width:"100%",
    height: 58,
    paddingLeft: 50,
    marginLeft: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#444",
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 6,
  },
  icon: {
    position: "absolute",
    left: 12,
    color: colors.gray_darken_2,
  },
  iconEye: {
    position: "absolute",
    right: 0,
    color: colors.gray_darken_2,
  },
  button: {
    backgroundColor: colors.orange,
    padding: 0,
    width: "100%",
    height: 58,
    borderRadius: 6,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
  signUpContainer: {
    //flex: 1,
    justifyContent: "center",
    alignItems: "stretch",    
    backgroundColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: -5,
    },
    shadowRadius: 4.5,
    shadowOpacity: 0.17,
    shadowColor: "black",
    //position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
  },
  signUpButton: {
    height: 90,
    flex: 1,
    justifyContent: "center",
  },
  signUp: {
    fontSize: 19,
    fontWeight: "600",
    color: "white",
    textAlign: "center",
    fontFamily: "Roboto",
  },
  buttonForgot: {},
  forgotText: {
    color: "#444",
    fontStyle: "italic",
    fontSize: 17,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderStyle: "solid",
  },
  rememberText: {
    color: colors.subtitle,
    fontStyle: "italic",
    fontSize: 15,
    marginRight: 5,
  },
  versionText: {
    color: colors.subtitle,
    fontStyle: "italic",
    fontSize: 14,
    alignSelf: "center",
    marginTop: 5,
  },
  rememberView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    paddingBottom: 10,
  },
});
