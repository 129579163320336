import { combineReducers } from 'redux';
import userReducer from "./user";
import registerReducer from "./register";
import tasksReducer from "./tasks";
import submissionsReducer from "./submissions";
import formsReducer from "./forms";
import loggroupsReducer from "./loggroups";
import recordsReducer from "./records";
export default combineReducers({
  user: userReducer,    
  register: registerReducer,
  tasks: tasksReducer,
  submissions: submissionsReducer,
  forms: formsReducer,
  loggroups: loggroupsReducer,
  records: recordsReducer,
});