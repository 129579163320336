import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { 
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT,
  AUTH_TOKENS_UPDATED,
  LOAD_STORAGE,
  LOGIN_LOADING_REPONSE,
  NAVIGATE_MENU,
} from '../constants/actions';

import StorageService from '../services/storage';

const initialState = {
  username: '',
  authToken: '',
  refreshToken: '',
  loginLoading: false,
};

export default handleActions({
  [LOGIN_REQUEST]: (state, action) => {
    //const { username, password } = action.payload;
    const { username } = action.payload;
    StorageService.set('username', username);
    //LocalStorageDriver.set('password', password);
    return state.set('username', username).set('loginLoading',true);
      //.set('password', password);
  },
  [LOGIN_RESPONSE]: {
    next(state, action) {
      return state;
    },
    throw(state, action) {
      alert('Login failed');
      return state;
    },
  },
  [LOGIN_LOADING_REPONSE]: {
    next(state, action) {
      return state.set('loginLoading',false);
    },   
  },
  [LOGOUT]: (state, action) => {

    console.log('***** LOGOUT reducer!');

    StorageService.remove('username');
    StorageService.remove('authToken');
    StorageService.remove('refreshToken');
    return state.set('authToken', '')
      .set('refreshToken', '');
  },
  [AUTH_TOKENS_UPDATED]: (state, action) => {
    if(action.payload){
    StorageService.set('authToken', action.payload.token);
    StorageService.set('refreshToken', action.payload.refresh);
    return state.set('authToken', action.payload.token)
      .set('refreshToken', action.payload.refresh);
    }else{
      alert('Wrong Email and Password');
      return state.set('authToken', '')
      .set('refreshToken', '');
    }
  },
  [LOAD_STORAGE]: (state, action) => {
    console.log('LOAD STORAGE ********');
    return state.set('authToken', StorageService.get('authToken'))
      .set('refreshToken', StorageService.get('refreshToken'));
  },
  [NAVIGATE_MENU]: (state, action) => {
    if(action.payload){
      const {token, refresh}= action.payload;
      
      return state.set('authToken', token)
        .set('refreshToken', refresh);
    }else{
      return state.set('authToken', '')
        .set('refreshToken', '');
    }

  }
}, Immutable.fromJS(initialState));