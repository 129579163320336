import { createAction } from '@reduxjs/toolkit'
import {
  SET_ALL_TASKS,
  SET_TASK_SELECTED,
  SET_FILTER_SELECTED,
  SET_FORM_SELECTED,
  SHOULD_RELOAD_TASKS,
  SET_ARCHIVE_RECENTLY,
  ARCHIVE_TASKS,
  CREATE_NEW_TASK,
  SET_FORMS_FROM_API,
  SIGN_TASK,
  REMOVE_TASK,
  SET_TASKS_STATUS,
} from "../constants/actions";
import { navigateForm } from "./menu";
import { getAllTasks, archiveTask, saveTasks, getForms, signTask, removeTask, insertBase64 } from "../api";

export function getTasks() {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASKS_STATUS)("loading"));
    getAllTasks(dispatch, getState).then(
      (response) => {        
        dispatch(createAction(SET_ALL_TASKS) (response)); 
        return response;
      });
    
  }
}

export function archiveTaskAction(task) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASKS_STATUS)("loading"));
    archiveTask(dispatch, getState, task).then(
      (response) => {      
          
        dispatch(createAction(SET_ALL_TASKS) (response)); 
        dispatch(createAction(SHOULD_RELOAD_TASKS)(true));
        return response;
      });
    //dispatch(createAction(SET_ALL_TASKS)(archiveTask(dispatch, getState, task)));
    
  };
}



export function setAllTasks(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_ALL_TASKS)(data));
    dispatch(createAction(SHOULD_RELOAD_TASKS)(false));
  };
}

export function setTaskSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASK_SELECTED)(data));
  };
}
export function setFilterSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_FILTER_SELECTED)(data));
  };
}
export function setFormSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_FORM_SELECTED)(data));
  };
}
export function shouldReloadTasks(value) {
  return (dispatch, getState) => {
    dispatch(createAction(SHOULD_RELOAD_TASKS)(value));
  };
}

export function createNewTask(task) {
  return (dispatch, getState) => {
    dispatch(createAction(SHOULD_RELOAD_TASKS)(true));
    const promise = saveTasks(dispatch, getState, task)
        .then((response) => {
          dispatch(createAction(CREATE_NEW_TASK)(response));
          //dispatch(createAction(SET_ALL_TASKS)(getAllTasks(dispatch, getState)));
          getAllTasks(dispatch, getState).then(
            (response) => {        
              dispatch(createAction(SET_ALL_TASKS) (response)); 
              return response;
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(createAction(INVALID_REGISTER_HIDE)(false));
          Alert.alert("Error",error + "");
          throw new Error("Register error");
        });
  };
}
export function setFormsFromAPI(task) {
  return (dispatch, getState) => {

    getForms(dispatch, getState).then(
      (response) => {        
        dispatch(createAction(SET_FORMS_FROM_API) (response)); 
        return response;
      });

    //dispatch(createAction(SET_FORMS_FROM_API)(getForms(dispatch, getState)));
  };
}
export function signTaskAction(signature) {
  return (dispatch, getState) => {
    insertBase64(dispatch, getState, signature.signature);
      signTask(dispatch, getState, signature).then((response) => {
          return dispatch(createAction(SIGN_TASK)(response));
      })
    
  };
}


export function removeTaskAction(task) { console.log("REQUST=>" + JSON.stringify(task));
  return (dispatch, getState) => {
      removeTask(dispatch, getState, task).then((response) => {  
        dispatch(createAction(REMOVE_TASK)( response));       
          return response;
      })
    
  };
}
